import React, { useContext, useState } from "react";
import { UserAuth } from "../../context/auth-context";
import { AccountSettingsContext } from "../../context/account-settings-context";
import { fetchPut } from "../../services/data-service";
import imagePlaceholder from "../../assets/image-placeholder.png";
import {
    Add as AddIcon,
    Close as CloseIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Save as SaveIcon,
    Cancel as CancelIcon,
} from "@mui/icons-material";

const MyProfile = () => {
    const { user } = UserAuth();
    const { account, setAccount } = useContext(AccountSettingsContext);
    const [newTag, setNewTag] = useState("");
    const [editing, setEditing] = useState(false);
    const [originalAccount, setOriginalAccount] = useState(account);

    const addTag = () => {
        if (newTag.trim()) {
            setAccount((prev) => ({
                ...prev,
                profile: {
                    ...prev.profile,
                    profileTags: [...prev.profile.profileTags, newTag.trim()],
                },
            }));
            setNewTag("");
        }
    };

    const removeTag = (index) => {
        setAccount((prev) => ({
            ...prev,
            profile: {
                ...prev.profile,
                profileTags: prev.profile.profileTags.filter(
                    (_, i) => i !== index
                ),
            },
        }));
    };

    const addProjectSection = () => {
        setAccount((prev) => ({
            ...prev,
            profile: {
                ...prev.profile,
                projects: [
                    ...prev.profile.projects,
                    { title: "", content: "", files: [] },
                ],
            },
        }));
    };

    const deleteProjectSection = async (index) => {
        setAccount((prev) => ({
            ...prev,
            profile: {
                ...prev.profile,
                projects: prev.profile.projects
                    .map((project, i) => {
                        if (i !== index) return project;
                        if (project.id) {
                            return { ...project, shouldDelete: true };
                        }
                        return project;
                    })
                    .filter((project, i) => {
                        return project.id ? true : i !== index;
                    }),
            },
        }));
    };

    const addProjectImage = async (e, sectionIndex, fileIndex) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            setAccount((prev) => ({
                ...prev,
                profile: {
                    ...prev.profile,
                    projects: prev.profile.projects.map((section, index) => {
                        if (index !== sectionIndex) return section;

                        const newFiles = [...section.files];
                        if (fileIndex < newFiles.length) {
                            newFiles[fileIndex] = {
                                url: URL.createObjectURL(file),
                                file,
                            };
                        } else {
                            newFiles.push({
                                url: URL.createObjectURL(file),
                                file,
                            });
                        }

                        return {
                            ...section,
                            files: newFiles,
                        };
                    }),
                },
            }));
        }
    };

    const deleteProjectImage = async (sectionIndex, fileIndex) => {
        setAccount((prev) => ({
            ...prev,
            profile: {
                ...prev.profile,
                projects: prev.profile.projects.map((section, index) => {
                    if (index !== sectionIndex) return section;

                    const filesCopy = [...section.files];
                    if (filesCopy[fileIndex].id) {
                        filesCopy[fileIndex] = {
                            ...filesCopy[fileIndex],
                            shouldDelete: true,
                        };
                    } else {
                        filesCopy.splice(fileIndex, 1);
                    }

                    return { ...section, files: filesCopy };
                }),
            },
        }));
    };

    const handleProfileInputChange = (e) => {
        const { name, value } = e.target;
        setAccount((prev) => ({
            ...prev,
            profile: {
                ...prev.profile,
                [name]: value,
            },
        }));
    };

    const handleProjectSectionChange = (e, sectionIndex) => {
        const { name, value } = e.target;
        setAccount((prev) => ({
            ...prev,
            profile: {
                ...prev.profile,
                projects: prev.profile.projects.map((section, index) =>
                    index === sectionIndex
                        ? { ...section, [name]: value }
                        : section
                ),
            },
        }));
    };

    const handleProfilePictureChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setAccount((prev) => ({
                ...prev,
                profile: {
                    ...prev.profile,
                    pictureFile: file,
                    pictureURL: URL.createObjectURL(file),
                },
            }));
        }
    };

    const handleCancel = () => {
        setAccount(originalAccount);
        setNewTag("");
        setEditing(false);
    };

    const handleSave = async () => {
        const saveProjects = async (projects) => {
            let orderedProjects = projects.map((project, idx) => ({
                ...project,
                order: idx,
            }));

            if (projects.length > 0) {
                const formData = new FormData();
                formData.append("userId", user.uid);

                // Include files data within each project
                formData.append(
                    "projects",
                    JSON.stringify(
                        orderedProjects.map((project) => ({
                            ...project,
                            files: project.files
                                ?.map((file) => {
                                    const fileData = {};
                                    if (file.file) fileData.shouldAdd = true;
                                    if (file.id) fileData.id = file.id;
                                    if (file.ref) fileData.ref = file.ref;
                                    if (file.shouldDelete)
                                        fileData.shouldDelete = true;
                                    return Object.keys(fileData).length > 0
                                        ? fileData
                                        : undefined;
                                })
                                .filter(Boolean),
                        }))
                    )
                );

                // Append files to the form data that will be added to the db.
                orderedProjects.forEach((project, projectIndex) => {
                    project.files?.forEach((fileObj, fileIndex) => {
                        if (fileObj.file) {
                            // Use a more specific key that includes project and file indices
                            formData.append(
                                `project${projectIndex}_file${fileIndex}`,
                                fileObj.file
                            );
                        }
                    });
                });

                const res = await fetchPut("/users/projects", formData);
                return res || [];
            }
            return [];
        };

        const saveProfile = async (profile) => {
            let res;
            if (profile.pictureFile) {
                const formData = new FormData();
                formData.append("pictureFile", profile.pictureFile);

                // Remove the file objects from the profile data as they can't be JSON serialized
                delete profile.pictureFile;

                // Add the rest of the profile data
                formData.append("id", user.uid);
                for (const [key, value] of Object.entries(profile)) {
                    if (Array.isArray(value)) {
                        value.forEach((item) =>
                            formData.append(`${key}[]`, item)
                        );
                    } else {
                        formData.append(key, value);
                    }
                }
                res = await fetchPut(`/users`, formData);
            } else {
                res = await fetchPut(`/users`, {
                    ...profile,
                    id: user.uid,
                });
            }
            return res || {};
        };

        const { pictureURL, projects, ...profile } = account.profile;
        const { id, ...updatedProfile } = await saveProfile(profile);
        const updatedProjects = await saveProjects(projects);

        setAccount((prev) => ({
            ...prev,
            profile: {
                ...updatedProfile,
                pictureURL: updatedProfile.pictureURL || pictureURL,
                projects: updatedProjects,
            },
        }));
        setEditing(false);
    };

    return (
        <div className="account-settings-public-profile">
            <h2 className="account-settings-heading">
                Edit Public Profile
                <div className="account-settings-button-container">
                    {editing && (
                        <button onClick={handleCancel}>
                            <CancelIcon
                                className="button-icon"
                                style={{ fontSize: "16px" }}
                            />
                            Cancel
                        </button>
                    )}
                    <button
                        onClick={() => {
                            if (!editing) {
                                setOriginalAccount(account);
                                setEditing(true);
                            } else {
                                handleSave();
                            }
                        }}
                    >
                        {editing ? (
                            <>
                                <SaveIcon
                                    className="button-icon"
                                    style={{ fontSize: "16px" }}
                                />
                                Save
                            </>
                        ) : (
                            <>
                                <EditIcon
                                    className="button-icon"
                                    style={{ fontSize: "16px" }}
                                />
                                Edit
                            </>
                        )}
                    </button>
                </div>
            </h2>
            {editing ? (
                <>
                    <div className="account-settings-form-group">
                        <div className="profile-picture-container">
                            <img
                                src={account.profile.pictureURL}
                                alt="Profile"
                                className="profile-picture"
                            />
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleProfilePictureChange}
                                className="profile-picture-upload"
                                id="profile-picture-upload"
                            />
                            <label
                                htmlFor="profile-picture-upload"
                                className="account-settings-button"
                            >
                                Upload New Picture
                            </label>
                        </div>
                    </div>

                    <div className="account-settings-form-group">
                        <label>Tags</label>
                        {account.profile.profileTags.length > 0 && (
                            <div className="tags-container">
                                {account.profile.profileTags.map(
                                    (tag, index) => (
                                        <span key={index} className="tag">
                                            {tag}
                                            <button
                                                onClick={() => removeTag(index)}
                                                className="remove-tag"
                                            >
                                                ×
                                            </button>
                                        </span>
                                    )
                                )}
                            </div>
                        )}
                        <div className="add-tag-container">
                            <input
                                type="text"
                                value={newTag}
                                onChange={(e) => setNewTag(e.target.value)}
                                onKeyPress={(e) =>
                                    e.key === "Enter" && addTag()
                                }
                                placeholder="Add a new tag"
                                className="account-settings-input"
                            />
                            <button
                                onClick={addTag}
                                className="account-settings-button add-tag-button"
                            >
                                Add
                            </button>
                        </div>
                    </div>

                    <div className="account-settings-form-group">
                        <label>Personal Description</label>
                        <textarea
                            name="description"
                            value={account.profile.description}
                            onChange={handleProfileInputChange}
                            className="account-settings-textarea"
                            rows={4}
                        />
                    </div>

                    <h3>Project Descriptions</h3>
                    {account.profile.projects
                        .filter((project) => !project.shouldDelete)
                        .map((section, sectionIndex) => (
                            <div
                                key={sectionIndex}
                                className="account-settings-section-wrapper"
                            >
                                <div className="account-settings-public-profile-section">
                                    <div className="account-settings-input-wrapper">
                                        <input
                                            placeholder="Section Title"
                                            name="title"
                                            value={section.title}
                                            onChange={(e) =>
                                                handleProjectSectionChange(
                                                    e,
                                                    sectionIndex
                                                )
                                            }
                                            className="account-settings-input"
                                        />
                                        <textarea
                                            placeholder="Content"
                                            name="content"
                                            value={section.content}
                                            onChange={(e) =>
                                                handleProjectSectionChange(
                                                    e,
                                                    sectionIndex
                                                )
                                            }
                                            className="account-settings-textarea"
                                        />
                                    </div>
                                </div>
                                <div className="account-settings-section-images">
                                    {Array.from({ length: 3 }).map(
                                        (_, fileIndex) => (
                                            <label
                                                key={fileIndex}
                                                className="account-settings-section-images-image-upload-label"
                                            >
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) =>
                                                        addProjectImage(
                                                            e,
                                                            sectionIndex,
                                                            fileIndex
                                                        )
                                                    }
                                                    style={{
                                                        display: "none",
                                                    }}
                                                />
                                                <div
                                                    className="account-settings-section-images-image-upload-square"
                                                    style={{
                                                        backgroundImage: `url(${
                                                            section.files[
                                                                fileIndex
                                                            ] &&
                                                            !section.files[
                                                                fileIndex
                                                            ]?.shouldDelete
                                                                ? section.files[
                                                                      fileIndex
                                                                  ].url
                                                                : imagePlaceholder
                                                        })`,
                                                    }}
                                                />
                                                {section.files[fileIndex] &&
                                                    !section.files[fileIndex]
                                                        ?.shouldDelete && (
                                                        <button
                                                            className="account-settings-section-images-delete-image"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                deleteProjectImage(
                                                                    sectionIndex,
                                                                    fileIndex
                                                                );
                                                            }}
                                                        >
                                                            <CloseIcon
                                                                style={{
                                                                    fontSize:
                                                                        "16px",
                                                                }}
                                                            />
                                                        </button>
                                                    )}
                                            </label>
                                        )
                                    )}
                                </div>
                                <button
                                    onClick={() =>
                                        deleteProjectSection(sectionIndex)
                                    }
                                    className="account-settings-section-button"
                                >
                                    <DeleteIcon style={{ fontSize: "12px" }} />
                                    Delete Section
                                </button>
                            </div>
                        ))}
                    <div className="account-settings-editor-buttons">
                        <button
                            className="account-settings-section-button"
                            onClick={addProjectSection}
                        >
                            <AddIcon style={{ fontSize: "24px" }} /> Add Project
                            Section
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <img
                        src={account.profile.pictureURL}
                        alt="Profile"
                        className="profile-picture"
                    />

                    <h3>Tags</h3>
                    {account.profile.profileTags.length > 0 && (
                        <div className="tags-container">
                            {account.profile.profileTags.map((tag, index) => (
                                <span key={index} className="tag">
                                    {tag}
                                </span>
                            ))}
                        </div>
                    )}

                    <h3>Personal Description</h3>
                    <p>{account.profile.description}</p>

                    <h3>Project Descriptions</h3>
                    {account.profile.projects.map((section, index) => (
                        <div key={index}>
                            <h4>{section.title}</h4>
                            <p>{section.content}</p>
                            <div className="account-settings-section-images">
                                {section.files?.map((file, fileIndex) => (
                                    <img
                                        key={fileIndex}
                                        src={file.url}
                                        alt={`Project ${index + 1} Image ${
                                            fileIndex + 1
                                        }`}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default MyProfile;
