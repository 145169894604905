import React, { useRef, useState, useEffect } from "react";
import Select from "react-select";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ErrorMessages } from "../../helpers/ListingHelpers";
import {
    AvailabilityCalendar,
    Loading,
    SelectPricing,
    SelectUnit,
} from "../../components";
import { unitsToJson } from "../../helpers/ListingHelpers";
import { quantityUnits, timeUnits } from "../../constants/ListingConstants";
import imagePlaceholder from "../../assets/image-placeholder.png";
import { useParams } from "react-router-dom";
import { getLabById } from "../../firebase/crud";

import { Close as CloseIcon } from "@mui/icons-material";
import "../../styles/CreateListingStyles.css";

const Service = ({ labMembers, listing }) => {
    const {
        register,
        control,
        formState: { errors },
        getValues,
        watch,
        setValue,
        reset,
    } = useFormContext();

    const pageRef = {
        about: useRef(null),
        pricing: useRef(null),
        delivery: useRef(null),
        misc: useRef(null),
    };

    const scrollToSection = (ref) => {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: "smooth",
        });
    };

    const watchLab = useWatch({
        name: "fields.lab",
        control,
        defaultValue: "myLab",
    });

    const handleImageChange = (file, index) => {
        const currentImages = getValues("fields.imgs");
        const updatedImages = [...currentImages];
        updatedImages[index] = {
            url: URL.createObjectURL(file),
            file,
            idx: index,
        };
        setValue("fields.imgs", updatedImages, { shouldDirty: true });
        console.log("updatedImages", updatedImages);
    };

    const handleImageDelete = (index) => {
        const currentImages = getValues("fields.imgs");
        const updatedImages = [...currentImages];
        updatedImages[index] = { url: null, file: null, idx: index };
        setValue("fields.imgs", updatedImages, { shouldDirty: true });
        console.log("updatedImages", updatedImages);
    };

    const watchIsCollaborative = useWatch({
        name: "fields.isCollaborative",
        control,
        defaultValue: false,
    });

    const watchServiceType = useWatch({
        name: "fields.serviceType",
        control,
        defaultValue: "quantityBased",
    });

    const handleServiceTypeChange = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setValue(name, true);
            setValue(
                name === "fields.quantityBasedService"
                    ? "fields.timeBasedService"
                    : "fields.quantityBasedService",
                false
            );
        } else {
            setValue(name, false);
        }
    };

    const { labId } = useParams();
    const [lab, setLab] = useState(null);

    useEffect(() => {
        const setListingValues = async () => {
            if (listing) {
                reset({
                    fields: {
                        title: listing.title,
                        description: listing.description,
                        labMember: listing.labMember,
                        licensingType: {
                            value: listing.licensingType,
                            label: listing.licensingType,
                        },
                        relevantLink: listing.relevantLink,
                        isCollaborative: listing.isCollaborative,
                        imgs: [
                            ...listing.imgs,
                            ...Array(3)
                                .fill(null)
                                .map((_, idx) => ({
                                    url: null,
                                    file: null,
                                    idx: listing.imgs.length + idx,
                                })),
                        ].slice(0, 3),

                        ...(listing.isCollaborative && {
                            price: {
                                value: listing.price?.value,
                                units: listing.price?.units,
                                customUnits: listing.price?.customUnits,
                            },
                            quantity: listing.quantity,
                            delivery: listing.delivery || "pickup",
                            location: listing.location || "",
                        }),
                    },
                });
            }
        };
        setListingValues();
    }, [listing]);

    useEffect(() => {
        getLabById(labId).then((lab) => setLab(lab));
    }, [labId]);

    if (!getValues("fields.imgs")) {
        return <Loading />;
    }

    return (
        <>
            <h1 className="create-listing-header">Service</h1>
            <div className="create-listing-container">
                <div className="create-listing-content">
                    <div ref={pageRef.about} className="create-listing-section">
                        <h2 className="create-listing-subheader">
                            Service Overview
                        </h2>
                        <div className="create-listing-field">
                            <h3>
                                Service Name
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                This is the name of the service that will be
                                given.
                            </h4>
                            <input
                                className={
                                    errors?.fields?.title
                                        ? "input input-error"
                                        : "input"
                                }
                                type="text"
                                name="title"
                                id="title"
                                placeholder="Title"
                                {...register("fields.title", {
                                    required: true,
                                })}
                            />
                            {errors?.fields?.title && (
                                <ErrorMessages message="The above field is required." />
                            )}
                        </div>
                        <div className="create-listing-field">
                            <h3>
                                Service Description
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                Describe your service in terms of the topics,
                                objectives, and requirements.
                            </h4>
                            <textarea
                                className={
                                    errors?.fields?.description
                                        ? "input input-error"
                                        : "input"
                                }
                                name="description"
                                id="description"
                                placeholder="Description"
                                {...register("fields.description", {
                                    required: true,
                                })}
                            />
                            {errors?.fields?.description && (
                                <ErrorMessages message="The above field is required." />
                            )}
                        </div>
                        <div className="create-listing-field">
                            <h3>
                                Lab Member in charge
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                The lab member will be in charge of offering the
                                service.
                            </h4>
                            <Controller
                                name="fields.labMember"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...field}
                                            className={
                                                errors?.fields?.labMember
                                                    ? "input-error labmember-select"
                                                    : "labmember-select"
                                            }
                                            options={labMembers}
                                            isSearchable
                                            placeholder="Assign lab member"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                        />
                                        {errors?.fields?.labMember && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className="create-listing-field">
                            <h3>Collaboration</h3>
                            <h4>
                                Collaborative items will be shown in the
                                marketplace.
                            </h4>
                            <Controller
                                name="fields.isCollaborative"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <FormControlLabel
                                        className="checkbox-label"
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value || false}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        e.target.checked
                                                    )
                                                }
                                                style={{
                                                    marginRight: "5px",
                                                    paddingRight: "0",
                                                    paddingTop: "0",
                                                    paddingBottom: "0",
                                                }}
                                            />
                                        }
                                        label="Allow collaboration"
                                    />
                                )}
                            />
                        </div>

                        {watchIsCollaborative && (
                            <div className="create-listing-field">
                                <h3>Type of Service</h3>
                                <h4>Select a type of service.</h4>
                                <RadioGroup defaultValue="quantityBased" row>
                                    <FormControlLabel
                                        className="checkbox-label"
                                        control={
                                            <Controller
                                                name="fields.serviceType"
                                                defaultValue="quantityBased"
                                                control={control}
                                                rules={{
                                                    required:
                                                        watchIsCollaborative,
                                                }}
                                                render={({ field }) => (
                                                    <Radio
                                                        {...field}
                                                        value="quantityBased"
                                                        style={{
                                                            color: "var(--primary-color)",
                                                        }}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        }
                                        label="Quantity-Based Service"
                                    />
                                    <FormControlLabel
                                        className="checkbox-label"
                                        control={
                                            <Controller
                                                name="fields.serviceType"
                                                defaultValue="timeBased"
                                                control={control}
                                                rules={{
                                                    required:
                                                        watchIsCollaborative,
                                                }}
                                                render={({ field }) => (
                                                    <Radio
                                                        {...field}
                                                        value="timeBased"
                                                        style={{
                                                            color: "var(--primary-color)",
                                                        }}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        }
                                        label="Time-Based Service"
                                    />
                                </RadioGroup>
                            </div>
                        )}
                    </div>

                    <div ref={pageRef.misc} className="create-listing-section">
                        <h2 className="create-listing-subheader">
                            Miscellaneous
                        </h2>
                        <div className="create-listing-field">
                            <h3>Listing Images</h3>
                            <h4>
                                Add an image of the item that will be sold. You
                                can add up to 3 images.
                            </h4>
                            <div className="image-input-container">
                                {watch("fields.imgs").map(({ url }, index) => (
                                    <label
                                        key={index}
                                        className="image-upload-label"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <input
                                            placeholder={imagePlaceholder}
                                            type="file"
                                            accept="image/*"
                                            {...register(
                                                `fields.imgs[${index}]`,
                                                {
                                                    onChange: (e) => {
                                                        handleImageChange(
                                                            e.target.files[0],
                                                            index
                                                        );
                                                    },
                                                }
                                            )}
                                            style={{ display: "none" }}
                                        />
                                        <div
                                            className="image-upload-square"
                                            style={{
                                                backgroundImage: `url(${
                                                    url || imagePlaceholder
                                                })`,
                                            }}
                                        />
                                        {index === 0 && (
                                            <span className="image-label">
                                                Primary
                                            </span>
                                        )}
                                        {url && (
                                            <button
                                                className="delete-image"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleImageDelete(index);
                                                }}
                                            >
                                                <CloseIcon
                                                    style={{
                                                        fontSize: "16px",
                                                    }}
                                                />
                                            </button>
                                        )}
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div className="create-listing-field">
                            <h3>Relevant Link</h3>
                            <h4>
                                Add a link to a relevant website, article,
                                publication, etc.
                            </h4>
                            <input
                                className={
                                    errors?.fields?.relevantLink
                                        ? "input input-error"
                                        : "input"
                                }
                                type="text"
                                name="relevantLink"
                                id="relevantLink"
                                placeholder="Relevant Link"
                                {...register("fields.relevantLink", {
                                    pattern:
                                        //eslint-disable-next-line
                                        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g,
                                })}
                            />
                            {errors?.fields?.relevantLink && (
                                <ErrorMessages message="The above link is invalid." />
                            )}
                        </div>
                    </div>

                    {watchIsCollaborative && (
                        <>
                            <div
                                ref={pageRef.pricing}
                                className="create-listing-section"
                            >
                                <h2 className="create-listing-subheader">
                                    Pricing & Availabilities
                                </h2>
                                <div className="create-listing-field">
                                    <h3>
                                        Price
                                        <span style={{ color: "red" }}>*</span>
                                    </h3>
                                    <h4>Set the price of the service.</h4>
                                    <div className="price-quantity-container">
                                        <SelectPricing
                                            isError={
                                                errors?.fields?.price?.value
                                            }
                                            fieldName="fields.price.value"
                                            required={watchIsCollaborative}
                                        />
                                        <div className="price-per-text">
                                            per
                                        </div>
                                        <SelectUnit
                                            isError={
                                                errors?.fields?.price?.units
                                            }
                                            isCustomError={
                                                errors?.fields?.price
                                                    ?.customUnits
                                            }
                                            fieldName="fields.price.units"
                                            customFieldName="fields.price.customUnits"
                                            unitOptions={unitsToJson(
                                                watchServiceType === "timeBased"
                                                    ? timeUnits
                                                    : quantityUnits
                                            )}
                                            required={watchIsCollaborative}
                                        />
                                    </div>
                                    {(errors?.fields?.price?.value ||
                                        errors?.fields?.price?.units ||
                                        errors?.fields?.price?.customUnits) && (
                                        <ErrorMessages message="The above field is required." />
                                    )}
                                </div>

                                <div className="create-listing-field">
                                    <AvailabilityCalendar />
                                </div>
                            </div>

                            <div
                                ref={pageRef.delivery}
                                className="create-listing-section"
                            >
                                <h2 className="create-listing-subheader">
                                    Service Location
                                </h2>

                                <div className="create-listing-field">
                                    <h3>
                                        Location Options
                                        <span style={{ color: "red" }}>*</span>
                                    </h3>
                                    <h4>Select the location of the service.</h4>
                                    <RadioGroup defaultValue="myLab" row>
                                        <FormControlLabel
                                            className="delivery-option"
                                            control={
                                                <Controller
                                                    name="fields.lab"
                                                    defaultValue="myLab"
                                                    control={control}
                                                    rules={{
                                                        required:
                                                            "Lab location is required",
                                                    }}
                                                    render={({ field }) => (
                                                        <Radio
                                                            {...field}
                                                            value="myLab"
                                                            style={{
                                                                color: "var(--primary-color)",
                                                            }}
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            }
                                            label="At my lab"
                                        />
                                        <FormControlLabel
                                            className="delivery-option"
                                            control={
                                                <Controller
                                                    name="fields.lab"
                                                    control={control}
                                                    defaultValue="otherLab"
                                                    rules={{
                                                        required:
                                                            "Lab location is required",
                                                    }}
                                                    render={({ field }) => (
                                                        <Radio
                                                            {...field}
                                                            value="otherLab"
                                                            style={{
                                                                color: "var(--primary-color)",
                                                            }}
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            }
                                            label="At another lab"
                                        />
                                    </RadioGroup>
                                </div>

                                {watchLab === "otherLab" && (
                                    <div className="create-listing-field">
                                        <h3>Service Location</h3>
                                        <h4>
                                            The address where the service will
                                            be offered if not at your lab.
                                        </h4>
                                        {/* <StandaloneSearchBox> */}
                                        <input
                                            defaultValue={lab?.addresses?.lab}
                                            className="input"
                                            type="text"
                                            placeholder="Lab Location"
                                            {...register("fields.location")}
                                        />
                                        {/* </StandaloneSearchBox> */}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Service;
