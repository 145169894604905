import { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { AccountSettingsProvider } from "../context/account-settings-context";
import "../styles/AccountSettings.css";

const MySettings = () => {
    const [activeTab, setActiveTab] = useState("publicProfile");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setActiveTab(location.pathname.split("/").pop());
    }, [location.pathname]);

    const tabs = {
        profile: "Public Profile",
        publications: "My Publications",
        settings: "Account",
    };

    return (
        <AccountSettingsProvider>
            <div className="account-settings-container">
                <div className="account-settings-tabs">
                    {Object.entries(tabs).map(([key, label]) => (
                        <button
                            key={key}
                            onClick={() => {
                                setActiveTab(key);
                                navigate(`/account-settings/${key}`);
                            }}
                            className={`account-settings-tab ${
                                activeTab === key ? "active" : ""
                            }`}
                        >
                            {label}
                        </button>
                    ))}
                </div>
                <div className="account-settings-content">
                    <Outlet />
                </div>
            </div>
        </AccountSettingsProvider>
    );
};

export default MySettings;
