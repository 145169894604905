import { Button } from "@mui/material";
import {
    collection,
    updateDoc,
    addDoc,
    doc,
    getDoc,
    setDoc,
} from "firebase/firestore";
import { uploadBytes, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { db, storage } from "../../firebase-config";
import { postNotification } from "../../firebase/crud";
import { getFileFromBase64 } from "../../utils/FileUtils";
import { fetchGet } from "../../services/data-service";
import "../../styles/Admin/Admin.css";

const Admin = () => {
    const [labRegistrations, setLabRegistrations] = useState([]);

    const fetchLabRegistrations = async () => {
        const labRegistrationsData = await fetchGet("/lab-registrations", true);
        setLabRegistrations(labRegistrationsData);
    };
    useEffect(() => {
        fetchLabRegistrations();
    }, []);

    const approveLab = async (labRegistration) => {
        // Approve the lab.
        const labRegistrationsDocRef = doc(
            db,
            "LabRegistrations",
            labRegistration.id
        );
        await updateDoc(labRegistrationsDocRef, {
            status: "approved",
        });

        // Create the lab.
        const { overview, status, logo, ...labData } = labRegistration;
        const labDocRef = doc(db, "Labs", labRegistration.id);
        setDoc(labDocRef, labData);

        const imageRef = ref(storage, `labs/${labDocRef.id}/images/logo`);
        await uploadBytes(imageRef, getFileFromBase64(logo, "logo"));

        // Update the user lab id.
        const userDocRef = doc(db, "Users", labRegistration.piId);
        const userDocSnapshot = await getDoc(userDocRef);
        const userData = userDocSnapshot.data();

        await updateDoc(userDocRef, {
            labId: labDocRef.id,
        });

        const memberDocRef = doc(labDocRef, "members", userDocRef.id);
        await setDoc(memberDocRef, {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: labRegistration.email,
            role: "pi",
            order: 0,
        });

        const overviewDocRef = collection(labDocRef, "overview");
        await addDoc(overviewDocRef, {
            order: 0,
            files: [],
            title: "Lab Overview",
            content: overview,
        });

        postNotification({
            userId: labRegistration.piId,
            title: "Lab Registration Approved",
            content: "Your lab registration has been approved.",
            url: `/lab/${labDocRef.id}`,
        });
        fetchLabRegistrations();
    };

    const rejectLab = async (labRegistration) => {
        await updateDoc(doc(db, "LabRegistrations", labRegistration.id), {
            status: "rejected",
        });
        postNotification({
            userId: labRegistration.piId,
            title: "Lab Registration Rejected",
            content: "Your lab registration has been rejected.",
            url: "",
        });
        fetchLabRegistrations();
    };

    const LabRegistrationComponent = ({ labRegistration }) => {
        return (
            <div
                key={labRegistration.id}
                className="lab-registration-component"
            >
                <div className="lab-registration-info">
                    <div>
                        <span style={{ fontWeight: 700 }}>Lab Name: </span>
                        {labRegistration.name}
                    </div>
                    <div>
                        <span style={{ fontWeight: 700 }}>Overview: </span>
                        {labRegistration.overview}
                    </div>
                    <div>
                        <span style={{ fontWeight: 700 }}>PI Email: </span>
                        {labRegistration.email}
                    </div>
                </div>
                <div className="lab-registration-tags">
                    <span>{labRegistration.institutionName}</span>
                    <span>{labRegistration.facultyName}</span>
                    <span>{labRegistration.departmentName}</span>
                </div>
                <div className="lab-registration-actions">
                    {labRegistration.status === "pending" && (
                        <>
                            <button onClick={() => approveLab(labRegistration)}>
                                Approve
                            </button>
                            <button onClick={() => rejectLab(labRegistration)}>
                                Reject
                            </button>
                        </>
                    )}
                    {labRegistration.status === "approved" && (
                        <div>Approved</div>
                    )}
                    {labRegistration.status === "rejected" && (
                        <div>Rejected</div>
                    )}
                </div>
            </div>
        );
    };

    const LabRegistrationList = ({ labRegistrations }) => {
        return (
            <div className="lab-registration-list">
                <h1 style={{ textAlign: "center", fontSize: "24px" }}>
                    Lab Registrations
                </h1>
                {labRegistrations.map((labRegistration) => (
                    <div
                        key={labRegistration.id}
                        className="lab-registration-container"
                    >
                        <LabRegistrationComponent
                            labRegistration={labRegistration}
                        />
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="admin-container">
            Admin Page
            <LabRegistrationList
                labRegistrations={labRegistrations}
            ></LabRegistrationList>
        </div>
    );
};

export default Admin;
