import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/auth-context";
import { db } from "../../firebase-config";
import Loading from "../Loading";

export default function MemberRoute({ children }) {
    const { labId } = useParams();
    const { user, loading } = UserAuth();
    const navigate = useNavigate();
    const [access, setAccess] = useState(false);
    const [memberLoading, setMemberLoading] = useState(true);

    useEffect(() => {
        const checkMemberEntitlement = async () => {
            const membersSnaps = await getDocs(
                collection(db, "Labs", labId, "members")
            );
            const members = membersSnaps.docs.map((docSnap) => {
                return { id: docSnap.id, ...docSnap.data() };
            });
            const member = members.find((member) => member.id === user.uid);
            if (member) {
                setAccess(true);
            } else {
                navigate(`/lab/${labId}`, { replace: true });
            }
            setMemberLoading(false); 
        };
        if (loading) return;
        if (!user) {
            navigate("/", { replace: true });
        } else if (!user.emailVerified) {
            navigate("/email-verification", { replace: true });
        } else if (!user.labId) {
            navigate("/labs", { replace: true });
        } else {
            checkMemberEntitlement();
        }
    }, [navigate, user, setAccess, labId, loading]);

    if (loading || memberLoading) {
        // Show loading until both checks are done
        return <Loading />;
    }

    return access ? children : <div>Not a member</div>;
}
