import React, { useState, useEffect } from "react";
import { fetchGet } from "../../services/data-service";
import { useParams } from "react-router-dom";

const ResearchOverview = () => {
    const { labId } = useParams();
    const [overview, setOverview] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchGet(`/labs/${labId}?overview=true`);
            setOverview(data.sections);
        };

        fetchData();
    }, [labId]);

    return (
        <div className="lab-profile-content">
            {overview.map((section, index) => (
                <div
                    key={index}
                    className="lab-profile-content-overview-section-wrapper"
                >
                    <h3>{section.title}</h3>
                    <p style={{ whiteSpace: "pre-line" }}>{section.content}</p>
                    {section.files.length > 0 && (
                        <div className="overview-section-images">
                            {section.files.map((file) => (
                                <div
                                    key={file.url}
                                    className="overview-section-images-container"
                                >
                                    <img src={file.url} alt={file.name} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ResearchOverview;
