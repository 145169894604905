import React from "react";
import "../styles/components/Switch.css";

const Switch = ({ checked, onChange }) => {
    return (
        <label className="switch">
            <input type="checkbox" checked={checked} onChange={onChange} />
            <span className="slider round"></span>
        </label>
    );
};

export default Switch;
