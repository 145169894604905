import { createContext, useState, useEffect } from "react";

export const LabProfileContext = createContext({
    selectedPublication: null,
    publicationModalOpen: false,
});

export const LabProfileContextProvider = ({ children }) => {
    const [selectedPublication, setSelectedPublication] = useState(null);
    const [publicationModalOpen, setPublicationModalOpen] = useState(false);

    useEffect(() => {
        return () => {
            setSelectedPublication(null);
            setPublicationModalOpen(false);
        };
    }, []);

    return (
        <LabProfileContext.Provider
            value={{
                selectedPublication,
                setSelectedPublication,
                publicationModalOpen,
                setPublicationModalOpen,
            }}
        >
            {children}
        </LabProfileContext.Provider>
    );
};
