import { createContext, useEffect, useState } from "react";
import { UserAuth } from "./auth-context";
import { fetchGet } from "../services/data-service";
import imagePlaceholder from "../assets/image-placeholder.png";

export const AccountSettingsContext = createContext();

export const AccountSettingsProvider = ({ children }) => {
    const [account, setAccount] = useState({
        profile: {
            pictureURL: "",
            profileTags: [],
            description: "",
            projects: [],
        },
        settings: {
            firstName: "",
            lastName: "",
            email: "",
            id: "",
        },
    });
    const { user } = UserAuth();

    useEffect(() => {
        const getData = async () => {
            if (user?.uid) {
                const projects = await fetchGet(
                    `/users/${user.uid}/projects`,
                    true
                );
                setAccount((prev) => ({
                    ...prev,
                    profile: {
                        pictureURL: user.profilePicture || imagePlaceholder,
                        profileTags: user.profileTags || [],
                        description: user.description || "",
                        projects: projects || [],
                    },
                    settings: {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        id: user.uid,
                    },
                }));
            }
        };
        getData();
    }, [user]);
    return (
        <AccountSettingsContext.Provider value={{ account, setAccount }}>
            {children}
        </AccountSettingsContext.Provider>
    );
};
