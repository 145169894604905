import { auth } from "../firebase-config";
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL; // Replace with your actual API base URL

// Function to get the current user's ID token
async function getIdToken() {
    const user = auth.currentUser;
    if (user) {
        return user.getIdToken();
    }
    throw new Error("No user is signed in");
}

// Updated generic GET request function
async function fetchGet(endpoint, userAuth = false) {
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        if (userAuth) {
            const idToken = await getIdToken();
            headers["Authorization"] = `Bearer ${idToken}`;
        }
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: "GET",
            headers: headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in fetchGet:", error);
        throw error;
    }
}

// Updated generic POST request function
async function fetchPost(endpoint, data, userAuth = true) {
    try {
        const headers = {
            ...(!(data instanceof FormData) && {
                "Content-Type": "application/json",
            }),
        };
        if (userAuth) {
            const idToken = await getIdToken();
            headers["Authorization"] = `Bearer ${idToken}`;
        }
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: "POST",
            headers: headers,
            body: data instanceof FormData ? data : JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in fetchPost:", error);
        throw error;
    }
}

// New generic PUT request function
async function fetchPut(endpoint, data, userAuth = true) {
    try {
        const headers = {
            ...(!(data instanceof FormData) && {
                "Content-Type": "application/json",
            }),
        };

        if (userAuth) {
            const idToken = await getIdToken();
            headers["Authorization"] = `Bearer ${idToken}`;
        }

        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: "PUT",
            headers,
            body: data instanceof FormData ? data : JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in fetchPut:", error);
        throw error;
    }
}

// New generic PATCH request function
async function fetchPatch(endpoint, data, userAuth = true) {
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        if (userAuth) {
            const idToken = await getIdToken();
            headers["Authorization"] = `Bearer ${idToken}`;
        }
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: "PATCH",
            headers: headers,
            body: data instanceof FormData ? data : JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in fetchPatch:", error);
        throw error;
    }
}

// New generic DELETE request function
async function fetchDelete(endpoint, userAuth = true) {
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        if (userAuth) {
            const idToken = await getIdToken();
            headers["Authorization"] = `Bearer ${idToken}`;
        }
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: "DELETE",
            headers: headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in fetchDelete:", error);
        throw error;
    }
}

// Updated export statement
export { fetchGet, fetchPost, fetchPut, fetchPatch, fetchDelete };
