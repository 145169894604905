import React, { useState, useEffect } from "react";
import { ModalComponent } from "../../components";
import { db, storage } from "../../firebase-config";
import {
    doc,
    updateDoc,
    arrayUnion,
    Timestamp,
    arrayRemove,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import { useForm, useFormContext, FormProvider } from "react-hook-form";
import { ErrorMessages } from "../../helpers/ListingHelpers";
import { fetchGet } from "../../services/data-service";
import { JatsParser } from "../../components";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import "../../styles/LabManagerTabs/PublicationsManager.css";

const formatPublicationDate = (date) => {
    try {
        if (!date) return "";

        const formatWithTimezone = (d) => {
            // Set time to noon UTC to avoid timezone issues
            const adjusted = new Date(d);
            adjusted.setUTCHours(12);
            return adjusted.toISOString().slice(0, 10);
        };

        // If it's a string that's already in YYYY-MM-DD format
        if (typeof date === "string" && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
            return date;
        }

        // If it's a Timestamp
        if (date instanceof Timestamp) {
            return formatWithTimezone(date.toDate());
        }

        // If it's a Date object
        if (date instanceof Date) {
            return formatWithTimezone(date);
        }

        // If it's a string, try to parse it
        const parsedDate = new Date(date);
        if (!isNaN(parsedDate.getTime())) {
            return formatWithTimezone(parsedDate);
        }

        return "";
    } catch (error) {
        console.error("Error formatting date:", error, date);
        return "";
    }
};

const PublicationForm = ({ publication }) => {
    const {
        register,
        formState: { errors },
        reset,
        watch,
    } = useFormContext();

    const image = watch("image"); // Watch the image field for new uploads

    useEffect(() => {
        if (publication) {
            reset({
                title: publication.title,
                description: publication.description,
                authors: publication.authors.join(", "),
                doi: publication.doi,
                htmlLink: publication.htmlLink,
                publicationDate: formatPublicationDate(
                    publication.publicationDate
                ),
                journal: publication.journal,
                id: publication.id,
            });
        } else {
            reset({
                title: "",
                description: "",
                authors: "",
                doi: "",
                htmlLink: "",
                publicationDate: "",
                journal: "",
            });
        }
    }, [publication, reset]);

    return (
        <form className="publication-manager-item-editor">
            <div className="publication-manager-item-editor-input">
                <h4>Publication Title</h4>
                <input
                    className={errors?.title && "input-error"}
                    type="text"
                    placeholder="Title of Article, Book, etc."
                    {...register("title", {
                        required: true,
                    })}
                />
                {errors?.title && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>Authors</h4>
                <input
                    className={errors?.authors && "input-error"}
                    type="text"
                    name="authors"
                    placeholder="Authors"
                    {...register("authors", {
                        required: true,
                    })}
                />
                {errors?.authors && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            {/* Added Image Upload Field */}
            <div className="publication-manager-item-editor-input">
                <h4>Graphical Abstract</h4>
                <input
                    type="file"
                    accept="image/*"
                    {...register("image", {
                        validate: {
                            isImage: (files) => {
                                if (files.length === 0) return true; // Optional field
                                const file = files[0];
                                return (
                                    file.type.startsWith("image/") ||
                                    "Please upload a valid image."
                                );
                            },
                        },
                    })}
                />
                {/* Show either the new uploaded image or the existing one */}
                <div className="image-preview">
                    {image?.[0] ? (
                        <img
                            src={URL.createObjectURL(image[0])}
                            alt="Preview"
                        />
                    ) : publication?.images?.[0] ? (
                        <img src={publication.images[0]} alt="Current" />
                    ) : null}
                </div>
                {errors?.image && (
                    <ErrorMessages message={errors.image.message} />
                )}
            </div>
            {/* End of Image Upload Field */}
            <div className="publication-manager-item-editor-input">
                <h4>Short Description</h4>
                <textarea
                    className={errors?.description && "input-error"}
                    name="description"
                    placeholder="Simple description of the publication"
                    {...register("description", {
                        required: true,
                    })}
                />
                {errors?.description && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>Publication Date</h4>
                <input
                    className={errors?.publicationDate && "input-error"}
                    type="date"
                    name="publicationDate"
                    label="Publication Date"
                    {...register("publicationDate", {
                        required: true,
                    })}
                />
                {errors?.publicationDate && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>Journal</h4>
                <input
                    className={errors?.journal && "input-error"}
                    type="text"
                    name="journal"
                    placeholder="Journal Name"
                    {...register("journal", {
                        required: true,
                    })}
                />
                {errors?.journal && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>DOI</h4>
                <input
                    className={errors?.doi && "input-error"}
                    type="text"
                    name="doi"
                    placeholder="Full DOI Link"
                    {...register("doi", {
                        required: true,
                    })}
                />
                {errors?.doi && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
            <div className="publication-manager-item-editor-input">
                <h4>Article HTML</h4>
                <input
                    className={errors?.htmlLink && "input-error"}
                    type="text"
                    name="htmlLink"
                    placeholder="Article HTML Link"
                    {...register("htmlLink", {
                        required: true,
                    })}
                />
                {errors?.htmlLink && (
                    <ErrorMessages message="The above field is required." />
                )}
            </div>
        </form>
    );
};

const PublicationItem = ({
    publication,
    onSave,
    onDelete,
    creatorName,
    labId,
}) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isPublicationModalOpen, setIsPublicationModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const {
        register,
        trigger,
        formState: { errors },
        getValues,
    } = useForm();
    const [displayState, setDisplayState] = useState(
        publication.displayInLabs?.includes(labId) ?? false
    );

    const handleImageChange = (e, index) => {
        // Existing image handling logic can remain or be modified as needed
    };

    // const handleDeleteImage = (indexToDelete) => {
    //     // Existing image delete logic
    // };

    const handleSave = async (publication) => {
        await onSave(publication);
        setIsPublicationModalOpen(false); // Close modal after save
    };

    const handleDisplayStateChange = async (event) => {
        const newDisplayState = event.target.value === "true";
        setDisplayState(newDisplayState);

        try {
            // Instead of a single boolean, store lab-specific display states
            const publicationRef = doc(db, "Publications", publication.id);

            if (newDisplayState) {
                // Add this lab to the list of labs where the publication should be displayed
                await updateDoc(publicationRef, {
                    displayInLabs: arrayUnion(labId),
                    displayInProfileTimestamp: Timestamp.now(),
                });
            } else {
                // Remove this lab from the list of labs where the publication should be displayed
                await updateDoc(publicationRef, {
                    displayInLabs: arrayRemove(labId),
                    // Only remove timestamp if not displayed in any lab
                    ...(publication.displayInLabs?.length <= 1 && {
                        displayInProfileTimestamp: null,
                    }),
                });
            }

            // Update local state
            publication.displayInLabs = newDisplayState
                ? [...(publication.displayInLabs || []), labId]
                : (publication.displayInLabs || []).filter(
                      (id) => id !== labId
                  );
        } catch (error) {
            console.error("Error updating publication display state:", error);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleCloseEditor = () => {
        setIsPublicationModalOpen(false);
    };

    return (
        <>
            <article className="publication-manager-item">
                <h2>{publication.title || "Paper"}</h2>
                <div className="publication-authors">
                    {publication.authors.join(", ") || "Authors"}
                </div>
                {/* Display Uploaded Image */}
                {publication.images && publication.images[0] && (
                    <div className="publication-image">
                        <img src={publication.images[0]} alt="Publication" />
                    </div>
                )}
                {/* End of Uploaded Image */}
                <p className="publication-description">
                    <JatsParser>
                        {publication.description ||
                            "Simple description of the article"}
                    </JatsParser>
                </p>
                <div className="publication-meta">
                    <div className="publication-date">
                        {publication.publicationDate
                            ? `Published on ${formatPublicationDate(
                                  publication.publicationDate
                              )}`
                            : ""}
                    </div>
                    <div className="publicationmanager-journal">
                        {publication.journal || "Journal"}
                    </div>
                    <div className="publication-links">
                        <a
                            href={publication.doi || "#"}
                            className="publication-doi"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {publication.doi
                                ? `DOI: ${publication.doi}`
                                : "DOI:"}
                        </a>
                        <div className="publications-manager-editor-left-buttons">
                            <a
                                href={publication.htmlLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Article HTML
                            </a>
                        </div>
                    </div>
                    <div className="publication-creator">
                        Added by{" "}
                        <a
                            style={{ textDecoration: "none" }}
                            href={`/profile/${publication.userId}`}
                        >
                            {creatorName}
                        </a>
                    </div>
                </div>
                <div className="publications-manager-editor-buttons">
                    <div className="publications-manager-editor-right-buttons">
                        <select
                            value={displayState.toString()}
                            onChange={handleDisplayStateChange}
                            className="publication-display-state"
                        >
                            <option value="true">Display in lab profile</option>
                            <option value="false">Do not display</option>
                        </select>
                        <button onClick={() => setIsPublicationModalOpen(true)}>
                            <EditIcon style={{ fontSize: "20px" }} />
                        </button>
                        <button onClick={() => setIsDeleteModalOpen(true)}>
                            <DeleteIcon style={{ fontSize: "20px" }} />
                        </button>
                    </div>
                </div>
                {/* Conditional rendering based on viewport size */}
                {!isMobile ? (
                    <ModalComponent
                        isOpen={isPublicationModalOpen}
                        toggle={handleCloseEditor}
                        title="Edit Publication"
                        submitText="Save"
                        onSubmit={() => handleSave(publication)}
                    >
                        <PublicationForm publication={publication} />
                    </ModalComponent>
                ) : (
                    <>
                        <div
                            className={`publication-editor-overlay ${
                                isPublicationModalOpen ? "active" : ""
                            }`}
                            onClick={handleCloseEditor}
                        />
                        <div
                            className={`publication-editor-slideup ${
                                isPublicationModalOpen ? "active" : ""
                            }`}
                        >
                            <div className="publication-editor-card">
                                <button
                                    className="publication-editor-close-button"
                                    onClick={handleCloseEditor}
                                >
                                    ×
                                </button>
                                <h2>Edit Publication</h2>
                                <PublicationForm publication={publication} />
                                <div className="publication-editor-footer">
                                    <button
                                        onClick={() => handleSave(publication)}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <ModalComponent
                    isOpen={isDeleteModalOpen}
                    toggle={() => setIsDeleteModalOpen(false)}
                    title="Remove Lab Association"
                    onSubmit={() => onDelete(publication.id)}
                    submitText="Remove"
                >
                    <p>
                        Are you sure you want to remove this publication's
                        association with this lab?
                    </p>
                    <p className="modal-subtitle">
                        Note: This will only remove the publication from this
                        lab's list. The publication will still be available in
                        the author's publications.
                    </p>
                </ModalComponent>
            </article>
        </>
    );
};

const PublicationsManager = () => {
    const [publicationsList, setPublicationsList] = useState([]);
    const [isConnectingORCID, setIsConnectingORCID] = useState(false);
    const { labId } = useParams();
    const methods = useForm();

    useEffect(() => {
        const getData = async () => {
            const publications = await fetchGet(
                `/labs/${labId}/publications`,
                false
            );
            const sortedPublications = publications
                .map((pub) => ({
                    ...pub,
                    displayInProfile: pub.displayInProfile ?? false,
                }))
                .sort((a, b) => {
                    // Convert both dates to timestamps for comparison
                    const dateA =
                        a.publicationDate instanceof Timestamp
                            ? a.publicationDate.toDate().getTime()
                            : new Date(a.publicationDate).getTime();

                    const dateB =
                        b.publicationDate instanceof Timestamp
                            ? b.publicationDate.toDate().getTime()
                            : new Date(b.publicationDate).getTime();

                    return dateB - dateA; // Sort in descending order
                });

            // Fetch user data for each publication
            const publicationsWithCreators = await Promise.all(
                sortedPublications.map(async (pub) => {
                    const user = await fetchGet(`/users/${pub.userId}`, false);
                    return {
                        ...pub,
                        creatorName: `${user.firstName} ${user.lastName}`,
                    };
                })
            );

            setPublicationsList(publicationsWithCreators);
        };
        getData();
    }, [labId]);

    const savePublication = async (publication) => {
        if (Object.keys(methods.formState.dirtyFields).length === 0) return;

        const { authors, publicationDate, image, labs, ...rest } =
            methods.getValues();
        const updatedFields = {};

        if (methods.formState.dirtyFields.authors) {
            updatedFields.authors = authors
                .split(",")
                .map((author) => author.trim());
        }

        if (methods.formState.dirtyFields.publicationDate) {
            // Fix timezone offset by setting the time to noon UTC
            const date = new Date(publicationDate);
            date.setUTCHours(12);
            updatedFields.publicationDate = Timestamp.fromDate(date);
        }

        // Handle image upload if a new image is provided
        if (image && image[0]) {
            const imageRef = ref(
                storage,
                `publications/${publication.id}/image.jpg`
            );
            await uploadBytes(imageRef, image[0]);
            const downloadURL = await getDownloadURL(imageRef);
            updatedFields.images = arrayUnion(downloadURL);
        }

        // Handle labIds
        if (methods.formState.dirtyFields.labs) {
            updatedFields.labIds = labs.map((lab) => lab.id);
        }

        // Handle other fields
        Object.keys(rest).forEach((key) => {
            updatedFields[key] = rest[key];
        });

        await updateDoc(doc(db, "Publications", publication.id), updatedFields);

        const updatedPublication = {
            ...publication,
            ...rest,
            ...updatedFields,
            authors: updatedFields.authors || publication.authors,
            publicationDate:
                updatedFields.publicationDate || publication.publicationDate,
            images: updatedFields.images
                ? [...(publication.images || []), updatedFields.images]
                : publication.images,
        };

        setPublicationsList((currentList) =>
            currentList.map((pub) =>
                pub.id === updatedPublication.id ? updatedPublication : pub
            )
        );
        // Modal will close automatically through the handleSave function in PublicationItem
    };

    const deletePublication = async (id) => {
        try {
            // Instead of deleting the publication, update it to remove this lab's ID
            const publicationRef = doc(db, "Publications", id);
            await updateDoc(publicationRef, {
                labIds: arrayRemove(labId), // Remove this lab's ID from the publication's labIds array
            });

            // Update local state to remove the publication from view
            setPublicationsList((currentList) =>
                currentList.filter((pub) => pub.id !== id)
            );
        } catch (error) {
            console.error("Error removing publication from lab:", error);
        }
    };

    const connectORCID = () => {
        const clientId = process.env.REACT_APP_ORCID_CLIENT_ID;
        const redirectUri = "https://api.labgiant.ca/orcid-authentication";
        const scope = "/authenticate";
        const url = `https://orcid.org/oauth/authorize?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${redirectUri}`;
        window.open(url, "_blank");
    };

    return (
        <div className="publications-manager-editor">
            <div className="listings-manager-filter">
                <h1 className="listings-manager-title">Publications</h1>
            </div>
            {/* <ModalComponent
                isOpen={isConnectingORCID}
                toggle={() => setIsConnectingORCID(false)}
                title={
                    <>
                        <img
                            style={{ width: "28px", height: "28px" }}
                            src={orcidLogo}
                            alt="ORCID"
                        />
                        Connect ORCID
                    </>
                }
                submitText="Connect"
                onSubmit={() => console.log("Connected")}
            >
                <p>
                    By connecting your ORCID, you can automatically import your
                    publications from your ORCID profile.
                </p>
                <div className="publications-manager-orcid-connect-field">
                    <h4>ORCID ID</h4>
                    <input
                        className="input"
                        type="text"
                        placeholder="XXXX-XXXX-XXXX-XXXX"
                    />
                </div>
                <div className="publications-manager-orcid-connect-field">
                    <h4>Password</h4>
                    <input
                        className="input"
                        type="password"
                        placeholder="Password"
                    />
                </div>
            </ModalComponent> */}
            <div className="publications-manager-editor-section-wrapper">
                <FormProvider {...methods}>
                    <div className="publications-manager-list">
                        {publicationsList.map((publication, index) => (
                            <PublicationItem
                                index={index}
                                key={publication.id}
                                publication={publication}
                                onSave={savePublication}
                                onDelete={deletePublication}
                                creatorName={publication.creatorName}
                                labId={labId}
                            />
                        ))}
                    </div>
                </FormProvider>
            </div>
        </div>
    );
};
export default PublicationsManager;
