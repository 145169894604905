import React, { useEffect, useState, useMemo } from "react";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
    writeBatch,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db, storage } from "../../firebase-config";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { postNotification } from "../../firebase/crud";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { Loading, ModalComponent } from "../../components";
import { fetchGet } from "../../services/data-service";
import { Card, CardContent, TextField, Button } from "@mui/material";
import {
    Cancel as CancelIcon,
    Save as SaveIcon,
    Edit as EditIcon,
    PersonRemove as PersonRemoveIcon,
} from "@mui/icons-material";
import "../../styles/LabManagerTabs/MembersManager.css";

const promoteToManager = async (labId, member, getMembers) => {
    try {
        const memberRef = await doc(db, "Labs", labId, "members", member.id);
        await updateDoc(memberRef, {
            role: "manager",
        });
        getMembers(labId);
    } catch (error) {
        console.error(error);
    }
};

const PromoteButton = ({ labId, member, getMembers }) => {
    return (
        <Button
            variant="contained"
            color="primary"
            size="small"
            className="promote-button"
            onClick={() => promoteToManager(labId, member, getMembers)}
        >
            Promote
        </Button>
    );
};

const demoteToMember = async (labId, member, getMembers) => {
    try {
        const memberRef = await doc(db, "Labs", labId, "members", member.id);
        await updateDoc(memberRef, {
            role: "member",
        });
        getMembers(labId);
    } catch (error) {
        console.error(error);
    }
};

const DemoteButton = ({ labId, member, getMembers }) => {
    return (
        <Button
            variant="contained"
            color="error"
            size="small"
            className="demote-button"
            onClick={() => demoteToMember(labId, member, getMembers)}
        >
            Demote
        </Button>
    );
};

const JoinRequestList = ({ requests, getJoinRequests, getMembers }) => {
    return requests.map((request) => (
        <JoinRequest
            key={request.id}
            request={request}
            getJoinRequests={getJoinRequests}
            getMembers={getMembers}
        />
    ));
};

const JoinRequest = ({ request, getJoinRequests, getMembers }) => {
    const approveRequest = async () => {
        const memberDocRef = doc(
            db,
            "Labs",
            request.labId,
            "members",
            request.userId
        );

        const members = await getDocs(
            collection(db, "Labs", request.labId, "members")
        );

        await setDoc(memberDocRef, {
            email: request.email,
            status: "active",
            userId: request.userId,
            firstName: request.firstName,
            lastName: request.lastName,
            role: "member",
            order: members.size,
            joinedAt: new Date(),
        });

        await updateDoc(
            doc(db, "Labs", request.labId, "requests", request.id),
            {
                status: "approved",
                joinedAt: new Date(),
            }
        );

        await updateDoc(
            doc(db, "Users", request.userId, "labRequests", request.id),
            {
                status: "approved",
                joinedAt: new Date(),
            }
        );

        // Update all other requests to "cancelled"
        const otherRequestsQuery = query(
            collection(db, "Users", request.userId, "labRequests"),
            where("status", "==", "pending")
        );

        const otherRequestsSnapshot = await getDocs(otherRequestsQuery);
        const batch = writeBatch(db);

        otherRequestsSnapshot.forEach((doc) => {
            batch.update(doc.ref, { status: "cancelled" });
        });

        await batch.commit();
        const userRef = doc(db, "Users", request.userId);

        await updateDoc(userRef, {
            labId: request.labId,
        });

        postNotification({
            userId: request.userId,
            title: "Lab Request Approved",
            content: `Your lab request for ${request.labName} has been approved.`,
            url: `/lab/${request.labId}`,
        });

        getJoinRequests(request.labId);
        getMembers(request.labId);
    };
    const rejectRequest = async () => {
        await updateDoc(
            doc(db, "Labs", request.labId, "requests", request.id),
            {
                status: "rejected",
            }
        );

        postNotification({
            userId: request.userId,
            title: "Lab Request Rejected",
            content: `Your lab request for ${request.labName} has been rejected.`,
            url: "",
        });

        getJoinRequests(request.labId);
        getMembers(request.labId);
    };
    return (
        <div className="join-request">
            <span className="request-info">
                <span className="request-name-email">
                    {request.firstName} {request.lastName} - {request.email}
                </span>
                <span className="request-institute">
                    <small>Institute</small>
                </span>
            </span>
            <div className="request-buttons">
                <button className="approve-button" onClick={approveRequest}>
                    Approve
                </button>
                <button className="reject-button" onClick={rejectRequest}>
                    Reject
                </button>
            </div>
        </div>
    );
};

const RemoveButton = ({ member, lab, getMembers }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const remove = async (userId, lab) => {
        const batch = writeBatch(db);
        try {
            const memberRef = doc(db, "Labs", lab.id, "members", userId);
            const userRef = doc(db, "Users", userId);
            batch.delete(memberRef);
            batch.update(userRef, { labId: null });
            await batch.commit();

            await postNotification({
                userId: userId,
                title: `Removed from ${lab.name}`,
                content: `You have been removed from ${lab.name}.`,
                url: "",
            });
            getMembers(lab.id);
            setIsModalOpen(false);
        } catch (error) {
            console.error("Error removing member:", error);
        }
    };
    return (
        <>
            <button
                className="lab-member-remove-button"
                onClick={() => setIsModalOpen(true)}
            >
                <PersonRemoveIcon />
            </button>
            <ModalComponent
                title={`Remove ${member.firstName} ${member.lastName}`}
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(!isModalOpen)}
                submitText="Remove"
                onSubmit={() => remove(member.id, lab)}
            >
                Are you sure you want to remove {member.firstName}{" "}
                {member.lastName} from {lab.name}?
            </ModalComponent>
        </>
    );
};

const LabMemberComponent = ({
    member,
    lab,
    getMembers,
    setSortedMembers,
    isSorting,
    index,
    moveItem,
}) => {
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const user = await fetchGet(`/users/${member.id}`, false);
                setProfileData(user);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchProfile();
    }, [member.id]);

    const handleRoleChange = async (event, member) => {
        const newRole = event.target.value;
        console.log(member);
        setSortedMembers((prevMembers) =>
            prevMembers.map((m) =>
                m.id === member.id ? { ...m, role: newRole } : m
            )
        );

        // try {
        //     const memberRef = doc(db, "Labs", lab.id, "members", member.id);
        //     await updateDoc(memberRef, {
        //         role: newRole,
        //     });
        //     getMembers(lab.id);
        // } catch (error) {
        //     console.error("Error updating member role:", error);
        // }
    };

    const getInitials = (firstName, lastName) => {
        return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
    };

    return (
        <div className={`lab-member-item ${isSorting ? "sorting" : ""}`}>
            <div className="lab-member-info">
                <div
                    className="lab-member-avatar"
                    onClick={() => navigate(`/profile/${member.id}`)}
                >
                    {profileData?.profilePicture ? (
                        <img
                            src={profileData.profilePicture}
                            alt={`${member.firstName} ${member.lastName}`}
                        />
                    ) : (
                        getInitials(member.firstName, member.lastName)
                    )}
                </div>
                <span className="lab-member-name-email">
                    {member.firstName} {member.lastName} - {member.email}
                </span>
            </div>
            <div className="assigned-resources-badges">
                <span className="assigned-resources-badge items">8 Items</span>
                <span className="assigned-resources-badge equipments">
                    5 Equipments
                </span>
                <span className="assigned-resources-badge services">
                    6 Services
                </span>
            </div>
            <div
                className={`lab-member-controls ${isSorting ? "sorting" : ""}`}
            >
                <div className="lab-member-permission-wrapper">
                    <span className="lab-member-permission-label">
                        Permission
                    </span>
                    {member.role !== "pi" ? (
                        <Select
                            value={member.role}
                            onChange={(e) => handleRoleChange(e, member)}
                            size="small"
                            className="lab-member-role-select"
                            disabled={!isSorting}
                        >
                            <MenuItem value="manager">Lab Manager</MenuItem>
                            <MenuItem value="member">Lab Member</MenuItem>
                        </Select>
                    ) : (
                        <Select
                            value={member.role}
                            onChange={handleRoleChange}
                            size="small"
                            className="lab-member-role-select"
                            disabled={true}
                        >
                            <MenuItem value="pi">
                                Principal Investigator
                            </MenuItem>
                        </Select>
                    )}
                </div>
            </div>
            {isSorting && member.role !== "pi" && (
                <div className="lab-member-sort visible">
                    <span
                        className="lab-member-sort-arrow"
                        onClick={() => moveItem(index, index - 1)}
                    >
                        ↑
                    </span>
                    <span
                        className="lab-member-sort-arrow"
                        onClick={() => moveItem(index, index + 1)}
                    >
                        ↓
                    </span>
                </div>
            )}
        </div>
    );
};

const MembersManager = () => {
    const { labId } = useParams();
    const [lab, setLab] = useState({});
    const [requests, setRequests] = useState([]);
    const [pi, setPi] = useState({});
    const [members, setMembers] = useState([]);
    const [isSorting, setIsSorted] = useState(false);
    const [sortedMembers, setSortedMembers] = useState([]);
    const labSize = useMemo(() => {
        return members.filter((member) => member.role !== "pi").length;
    }, [members]);
    const [groupPicture, setGroupPicture] = useState({
        current: null,
        editing: null,
    });
    const [groupDescription, setGroupDescription] = useState("");

    // const handleInputChange = (e) => {
    //     setInputValue(e.target.value);
    // };

    // const handleInputKeyDown = (e) => {
    //     if (e.key === "Enter" && inputValue.trim()) {
    //         e.preventDefault();
    //         const newEmails = [...emails, inputValue.trim()];
    //         setEmails(newEmails);
    //         setInputValue("");
    //     }
    // };

    // const removeEmail = (index) => {
    //     const newEmails = emails.filter((_, i) => i !== index);
    //     setEmails(newEmails);
    // };

    // const sendJoinRequests = () => {
    //     // Implement the logic to send join requests for all emails
    //     console.log("Sending join requests to:", emails);
    //     // Reset emails after sending
    //     setEmails([]);
    // };

    const getMembers = async (labId) => {
        const membersData = await fetchGet(
            `/labs/${labId}/members?groupDescription=true`,
            true
        );
        setGroupPicture({
            current: membersData.groupPicture,
            editing: membersData.groupPicture,
        });
        setGroupDescription(membersData.groupDescription);
        setMembers(membersData.members);
        setSortedMembers(membersData.members);
    };

    const getLab = async (labId) => {
        const labDoc = await getDoc(doc(db, "Labs", labId));
        setLab({ id: labDoc.id, ...labDoc.data() });
    };

    useEffect(() => {
        getMembers(labId);
        getJoinRequests(labId);
        getLab(labId);
    }, [labId]);

    const getJoinRequests = async (labId) => {
        const joinRequests = await fetchGet(
            `/labs/${labId}/requests?status=pending`,
            true
        );
        setRequests(joinRequests);
    };

    const handleSortStart = () => {
        setIsSorted(true);
        setSortedMembers([...members]);
    };

    const handleSortSave = async () => {
        await saveMembers();
        await saveGroupPicture();
        await saveGroupDescription();
        setIsSorted(false);
    };

    const moveItem = (fromIndex, toIndex) => {
        if (toIndex < 0 || toIndex >= sortedMembers.length) return;
        const newItems = [...sortedMembers];
        const [sortedItem] = newItems.splice(fromIndex, 1);
        newItems.splice(toIndex, 0, sortedItem);
        setSortedMembers(newItems);
    };

    const handleGroupPictureChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setGroupPicture({
                ...groupPicture,
                editing: {
                    file: file,
                    url: URL.createObjectURL(file),
                },
            });
        }
    };

    const saveMembers = async () => {
        const batch = writeBatch(db);

        sortedMembers.forEach((sortedMember, index) => {
            const originalMember = members.find(
                (member) => member.id === sortedMember.id
            );

            if (originalMember) {
                const memberRef = doc(
                    db,
                    "Labs",
                    labId,
                    "members",
                    sortedMember.id
                );
                if (
                    originalMember.order !== index ||
                    originalMember.role !== sortedMember.role
                ) {
                    batch.update(memberRef, {
                        order: index,
                        role: sortedMember.role,
                    });
                }
            }
        });

        await batch.commit();
    };

    const saveGroupPicture = async () => {
        if (groupPicture.editing && groupPicture.editing.file) {
            const imageRef = ref(storage, `labs/${labId}/images/group-picture`);
            await uploadBytes(imageRef, groupPicture.editing.file);
            const url = await getDownloadURL(imageRef);

            setGroupPicture({
                current: url,
                editing: url,
            });

            // Update the lab document in Firestore with the new group picture URL
            const labRef = doc(db, "Labs", labId);
            await updateDoc(labRef, {
                groupPictureUrl: url,
            });
        }
    };

    const handleGroupDescriptionChange = (event) => {
        setGroupDescription(event.target.value);
    };

    const saveGroupDescription = async () => {
        try {
            const labRef = doc(db, "Labs", labId);
            await updateDoc(labRef, {
                groupDescription: groupDescription,
            });
            console.log("Group description saved successfully");
        } catch (error) {
            console.error("Error saving group description:", error);
        }
    };

    const handleCancel = () => {
        // Revert all changes
        setSortedMembers(members);
        setGroupPicture({
            ...groupPicture,
            editing: groupPicture.current,
        });
        setGroupDescription(groupDescription);
        setIsSorted(false);
    };

    if (!lab && !pi && !members) {
        return <Loading />;
    }

    return (
        <div className="members-manager">
            {requests.length > 0 && (
                <Card className="join-requests-card">
                    <CardContent>
                        <h3>Requests to join</h3>
                        <JoinRequestList
                            requests={requests}
                            getJoinRequests={getJoinRequests}
                            getMembers={getMembers}
                        />
                    </CardContent>
                </Card>
            )}

            {/* Add the new join request form outside the card */}
            {/* <div className="join-request-form">
                <div className="join-request-input-container">
                    <textarea
                        className="join-request-input"
                        placeholder="Enter emails to send signup requests (press Enter after each email)"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyDown}
                    />
                    <button
                        className="send-join-request-button"
                        onClick={sendJoinRequests}
                    >
                        Send requests
                    </button>
                </div>
                <div className="email-tags">
                    {emails.map((email, index) => (
                        <span key={index} className="email-tag">
                            {email}
                            <span
                                className="email-tag-remove"
                                onClick={() => removeEmail(index)}
                            >
                                ×
                            </span>
                        </span>
                    ))}
                </div>
            </div> */}

            <div className="members-section">
                <div className="members-manager-content-wrapper lab-members-section">
                    <div className="members-manager-header">
                        <h1 className="listings-manager-title">Lab Members</h1>
                        <div className="members-manager-action-buttons">
                            {isSorting ? (
                                <>
                                    <button
                                        onClick={handleCancel}
                                        className="members-manager-button cancel"
                                    >
                                        <CancelIcon
                                            style={{ fontSize: "18px" }}
                                        />
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSortSave}
                                        className="members-manager-button save"
                                    >
                                        <SaveIcon
                                            style={{ fontSize: "18px" }}
                                        />
                                        Save
                                    </button>
                                </>
                            ) : (
                                <button
                                    onClick={handleSortStart}
                                    className="members-manager-button edit"
                                >
                                    <EditIcon style={{ fontSize: "18px" }} />
                                    Edit
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Group Picture Display */}
                    <div className="members-manager-group-info-wrapper">
                        <div className="members-manager-group-description">
                            <h4>Group Description</h4>
                            {isSorting ? (
                                <div className="members-manager-group-description-edit">
                                    <TextField
                                        multiline
                                        value={groupDescription}
                                        onChange={handleGroupDescriptionChange}
                                        placeholder="Enter group description"
                                        InputProps={{
                                            style: { resize: "none" },
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="members-manager-group-description-display">
                                    <div className="members-manager-group-description-content">
                                        {groupDescription ? (
                                            groupDescription
                                        ) : (
                                            <span className="no-description">
                                                No description available
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="members-manager-group-picture-wrapper">
                            <h4>Group Picture</h4>
                            <div className="members-manager-group-picture-display">
                                {isSorting ? (
                                    groupPicture.editing ? (
                                        <img
                                            src={
                                                groupPicture.editing.url ||
                                                groupPicture.editing
                                            }
                                            alt="Lab Group"
                                        />
                                    ) : (
                                        <div className="no-picture">
                                            No group picture uploaded
                                        </div>
                                    )
                                ) : groupPicture.current ? (
                                    <img
                                        src={groupPicture.current}
                                        alt="Lab Group"
                                    />
                                ) : (
                                    <div className="no-picture">
                                        No group picture uploaded
                                    </div>
                                )}
                            </div>
                            {isSorting && (
                                <div className="members-manager-group-picture-upload">
                                    <label className="members-manager-group-picture-upload-button">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleGroupPictureChange}
                                            style={{ display: "none" }}
                                        />
                                        {groupPicture.editing
                                            ? "Change Picture"
                                            : "Upload Picture"}
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="lab-members-list">
                        <h4>Principal Investigator</h4>
                        <div className="lab-member-remove-button-wrapper">
                            {(isSorting ? sortedMembers : members)
                                .filter((member) => member.role === "pi")
                                .map((member, index) => (
                                    <div
                                        key={index}
                                        className="lab-member-remove-button-wrapper"
                                    >
                                        <LabMemberComponent
                                            key={member.id}
                                            member={member}
                                            lab={lab}
                                            getMembers={getMembers}
                                            setSortedMembers={setSortedMembers}
                                            isSorting={isSorting}
                                            index={index}
                                            moveItem={moveItem}
                                        />
                                    </div>
                                ))}
                        </div>

                        <h4>
                            Members <span>({labSize})</span>
                        </h4>
                        {(isSorting ? sortedMembers : members)
                            .filter((member) => member.role !== "pi")
                            .map((member, index) => (
                                <div className="lab-member-remove-button-wrapper">
                                    <LabMemberComponent
                                        key={member.id}
                                        member={member}
                                        lab={lab}
                                        getMembers={getMembers}
                                        setSortedMembers={setSortedMembers}
                                        isSorting={isSorting}
                                        index={index}
                                        moveItem={moveItem}
                                    />
                                    {isSorting && (
                                        <RemoveButton
                                            member={member}
                                            lab={lab}
                                            getMembers={getMembers}
                                        />
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MembersManager;
