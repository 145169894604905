import React, { useContext, useState } from "react";
import { UserAuth } from "../../context/auth-context";
import { fetchPut } from "../../services/data-service";
import { AccountSettingsContext } from "../../context/account-settings-context";
import { Switch } from "../../components";
import "../../styles/AccountSettings.css";

const MySettings = () => {
    const { user } = UserAuth();
    const { account, setAccount } = useContext(AccountSettingsContext);

    const handleUserInfoChange = (e) => {
        const { name, value } = e.target;
        setAccount((prev) => ({
            ...prev,
            settings: { ...prev.settings, [name]: value },
        }));
    };

    const [notifications, setNotifications] = useState({
        receivedMessageNotification: false,
        receivedMessageEmail: false,
        newOrderNotification: false,
        newOrderEmail: false,
        joinLabRequestNotification: false,
        joinLabRequestEmail: false,
        newTechnologyNotification: false,
        newTechnologyEmail: false,
        newPublicationNotification: false,
        newPublicationEmail: false,
    });

    const handleNotificationChange = (setting) => {
        setNotifications((prev) => ({
            ...prev,
            [setting]: !prev[setting],
        }));
    };

    const saveUserData = async () => {
        const { firstName, lastName, email, id } = account.settings;
        if (
            user.uid === id &&
            user.email === email &&
            user.firstName === firstName &&
            user.lastName === lastName
        )
            return;

        await fetchPut("/users", {
            firstName,
            lastName,
            email,
            id,
        });
    };

    return (
        <div className="account-settings-account">
            <div className="account-settings-section">
                <h2 className="account-settings-heading">About You</h2>
                <div className="account-settings-form">
                    <div className="account-settings-form-group">
                        <div className="account-settings-form-group-row">
                            <label>First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={account.settings.firstName}
                                onChange={handleUserInfoChange}
                                className="account-settings-input"
                            />
                        </div>
                        <div className="account-settings-form-group-row">
                            <label>Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                value={account.settings.lastName}
                                onChange={handleUserInfoChange}
                                className="account-settings-input"
                            />
                        </div>
                        <div className="account-settings-form-group-row">
                            <label>Email</label>
                            <input
                                type="email"
                                name="email"
                                value={account.settings.email}
                                onChange={handleUserInfoChange}
                                className="account-settings-input"
                            />
                        </div>
                    </div>
                </div>
                <button
                    className="account-settings-button"
                    onClick={saveUserData}
                >
                    Save Changes
                </button>
            </div>

            <div className="account-settings-section">
                <h2 className="account-settings-heading">Password</h2>
                <div className="account-settings-form">
                    <div className="account-settings-form-group">
                        <div className="account-settings-form-group-row">
                            <label>Current Password</label>
                            <input
                                type="password"
                                className="account-settings-input"
                            />
                        </div>
                        <div className="account-settings-form-group-row">
                            <label>New Password</label>
                            <input
                                type="password"
                                className="account-settings-input"
                            />
                        </div>
                        <div className="account-settings-form-group-row">
                            <label>Confirm New Password</label>
                            <input
                                type="password"
                                className="account-settings-input"
                            />
                        </div>
                    </div>
                </div>
                <button className="account-settings-button">
                    Change Password
                </button>
            </div>

            <div className="account-settings-section">
                <h2 className="account-settings-heading">Your Notifications</h2>
                <table className="account-settings-notifications-table">
                    <thead>
                        <tr>
                            <th>Settings</th>
                            <th>Notification</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="account-settings-notifications-setting-header">
                                    <h1>Message Alerts</h1>
                                    <p>
                                        Receive alerts when someone sends you a
                                        direct message.
                                    </p>
                                </div>
                            </td>
                            <td>
                                <Switch
                                    checked={
                                        notifications.receivedMessageNotification
                                    }
                                    onChange={() =>
                                        handleNotificationChange(
                                            "receivedMessageNotification"
                                        )
                                    }
                                />
                            </td>
                            <td>
                                <Switch
                                    checked={notifications.receivedMessageEmail}
                                    onChange={() =>
                                        handleNotificationChange(
                                            "receivedMessageEmail"
                                        )
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="account-settings-notifications-setting-header">
                                    <h1>Order Alerts</h1>
                                    <p>
                                        Get alerted when a new order is placed
                                        by someone.
                                    </p>
                                </div>
                            </td>
                            <td>
                                <Switch
                                    checked={notifications.newOrderNotification}
                                    onChange={() =>
                                        handleNotificationChange(
                                            "newOrderNotification"
                                        )
                                    }
                                />
                            </td>
                            <td>
                                <Switch
                                    checked={notifications.newOrderEmail}
                                    onChange={() =>
                                        handleNotificationChange(
                                            "newOrderEmail"
                                        )
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="account-settings-notifications-setting-header">
                                    <h1>Join Lab Request Alerts</h1>
                                    <p>
                                        Be alerted when someone submits a
                                        request to join your lab.
                                    </p>
                                </div>
                            </td>
                            <td>
                                <Switch
                                    checked={
                                        notifications.joinLabRequestNotification
                                    }
                                    onChange={() =>
                                        handleNotificationChange(
                                            "joinLabRequestNotification"
                                        )
                                    }
                                />
                            </td>
                            <td>
                                <Switch
                                    checked={notifications.joinLabRequestEmail}
                                    onChange={() =>
                                        handleNotificationChange(
                                            "joinLabRequestEmail"
                                        )
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="account-settings-notifications-setting-header">
                                    <h1>New Technology Updates</h1>
                                    <p>
                                        Stay informed about new technology
                                        releases from labs you follow.
                                    </p>
                                </div>
                            </td>
                            <td>
                                <Switch
                                    checked={
                                        notifications.newTechnologyNotification
                                    }
                                    onChange={() =>
                                        handleNotificationChange(
                                            "newTechnologyNotification"
                                        )
                                    }
                                />
                            </td>
                            <td>
                                <Switch
                                    checked={notifications.newTechnologyEmail}
                                    onChange={() =>
                                        handleNotificationChange(
                                            "newTechnologyEmail"
                                        )
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="account-settings-notifications-setting-header">
                                    <h1>New Publications Updates</h1>
                                    <p>
                                        Receive updates when a new publication
                                        is released from labs you follow.
                                    </p>
                                </div>
                            </td>
                            <td>
                                <Switch
                                    checked={
                                        notifications.newPublicationNotification
                                    }
                                    onChange={() =>
                                        handleNotificationChange(
                                            "newPublicationNotification"
                                        )
                                    }
                                />
                            </td>
                            <td>
                                <Switch
                                    checked={notifications.newPublicationEmail}
                                    onChange={() =>
                                        handleNotificationChange(
                                            "newPublicationEmail"
                                        )
                                    }
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MySettings;
