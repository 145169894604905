import { useState, useEffect } from "react";
import "../styles/BrowseLabs.css";
import { Pagination } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Loading } from "../components";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/auth-context";
import { postNotification } from "../firebase/crud";
import {
    AccountBalance as AccountBalanceIcon,
    School as SchoolIcon,
    ImportContacts as ImportContactsIcon,
    Book as BookIcon,
    Psychology as PsychologyIcon,
    Person as PersonIcon,
} from "@mui/icons-material";
import { fetchGet, fetchPost } from "../services/data-service";

const BrowseLabs = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("query");

    const { user } = UserAuth();
    const [selectedListing, setSelectedListing] = useState(null);
    const [labs, setLabs] = useState(null);
    const [labPIs, setLabPIs] = useState({});
    const navigate = useNavigate();

    const selectLab = (listing) => {
        if (!selectedListing || selectedListing.name !== listing.name) {
            setSelectedListing(listing);
        } else {
            setSelectedListing(null);
        }
    };

    const requestJoin = async (lab) => {
        try {
            const labRequest = await fetchPost(
                `/labs/${lab.id}/requests`,
                {
                    userId: user.uid,
                    labId: lab.id,
                    labName: lab.name,
                    status: "pending",
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    createdAt: new Date(),
                },
                true
            );

            await fetchPost(
                `/users/${user.uid}/labRequests`,
                {
                    labRequestId: labRequest.id,
                    labId: lab.id,
                    labName: lab.name,
                    status: "pending",
                    createdAt: new Date(),
                },
                true
            );
            setLabs((prevLabs) =>
                prevLabs.map((l) =>
                    l.id === lab.id ? { ...l, hasRequested: true } : l
                )
            );
            setSelectedListing((prev) => ({
                ...prev,
                hasRequested: true,
            }));

            postNotification({
                userId: lab.piId,
                title: "New Lab Join Request!",
                content: `${user.firstName} ${user.lastName} has requested to join your lab ${lab.name}`,
                url: `/manager/${lab.id}/members`,
            });

            alert("Lab Join Request Sent!");
        } catch {
            console.error(`Failed to send join request to lab: ${lab.id}`);
        }
    };

    useEffect(() => {
        const fetchLabs = async () => {
            const labs = await fetchGet("/labs", false);
            labs.forEach((lab) => {
                lab.hasRequested = false;
                lab.overview = lab.overview[0]?.content;
            });

            const piIds = [...new Set(labs.map((lab) => lab.piId))];
            const piPromises = piIds.map((piId) =>
                fetchGet(`/users/${piId}`, false)
            );
            const pis = await Promise.all(piPromises);

            const piMap = {};
            pis.forEach((pi) => {
                piMap[pi.id] = `${pi.firstName} ${pi.lastName}`;
            });
            setLabPIs(piMap);

            if (user) {
                const labRequests = await fetchGet(
                    `/users/${user.uid}/labRequests?status=pending`,
                    false
                );
                labRequests.forEach((request) => {
                    labs.find(
                        (lab) => lab.id === request.labId
                    ).hasRequested = true;
                });
            }
            setLabs(labs);
        };
        fetchLabs();
    }, [user]);

    const LabFilter = () => {
        return (
            <div className="listings-filter-bar">
                <div className="listings-filter">
                    <h4 className="listings-filter-title">Institution:</h4>
                    <select>
                        <option value="">Select Institution</option>
                        {Array(6)
                            .fill(0)
                            .map((_, idx) => (
                                <option
                                    key={`school-${idx}`}
                                    value={`School ${idx}`}
                                >
                                    {`School ${idx}`}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="listings-filter">
                    <h4 className="listings-filter-title">Faculty:</h4>
                    <select>
                        <option value="">Select Faculty</option>
                        {Array(6)
                            .fill(0)
                            .map((_, idx) => (
                                <option
                                    key={`faculty-${idx}`}
                                    value={`Faculty ${idx}`}
                                >{`Faculty ${idx}`}</option>
                            ))}
                    </select>
                </div>
                <div className="listings-filter">
                    <h4 className="listings-filter-title">Keywords:</h4>
                    <input type="text" />
                </div>
                <div className="listings-filter">
                    <button className="listings-filter-button">Apply</button>
                </div>
            </div>
        );
    };

    if (!labs) {
        return <Loading />;
    }
    const Labs = () => {
        return (
            <ul>
                {labs.map((lab) => (
                    <li
                        key={lab.name.toString()}
                        onClick={() => selectLab(lab)}
                        style={
                            lab.name === selectedListing?.name
                                ? {
                                      borderRight: "4px solid #18181b",
                                      outlineOffset: "-4px",
                                  }
                                : {}
                        }
                    >
                        <div className="browse-labs-lab">
                            <div className="browse-labs-content">
                                <div className="browse-labs-logo">
                                    <img src={lab.logo} alt="lab" />
                                </div>
                                <div className="browse-labs-header-text">
                                    <h2>{lab.name}</h2>
                                    <ul className="browse-labs-header-info">
                                        <li>
                                            <PersonIcon
                                                style={{ fontSize: "14px" }}
                                            />
                                            {labPIs[lab.piId]}
                                        </li>
                                        <li>
                                            <AccountBalanceIcon
                                                style={{ fontSize: "14px" }}
                                            />
                                            {lab.institutionName}
                                        </li>
                                        <li>
                                            <SchoolIcon
                                                style={{ fontSize: "14px" }}
                                            />
                                            {lab.facultyName}
                                        </li>
                                        <li>
                                            <ImportContactsIcon
                                                style={{ fontSize: "14px" }}
                                            />
                                            {lab.departmentName}
                                        </li>
                                    </ul>
                                    <div className="browse-labs-description">
                                        <div className="browse-labs-description-section">
                                            <div className="browse-labs-description-lab-overview">
                                                {lab.overview}
                                            </div>
                                        </div>
                                        <div className="browse-labs-description-section">
                                            <div className="browse-labs-description-keywords">
                                                {lab.keywords.map((keyword) => (
                                                    <div key={keyword}>
                                                        {keyword}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    const SelectedListing = () => {
        return (
            <div className="selected-lab-card">
                <button
                    className="selected-lab-close-button"
                    onClick={() => setSelectedListing(null)}
                >
                    ×
                </button>
                <div className="selected-lab-header">
                    <div className="selected-lab-header-text">
                        <h2
                            className="selected-lab-header-lab"
                            onClick={() =>
                                navigate(`/lab/${selectedListing.id}`)
                            }
                        >
                            {selectedListing.name}
                        </h2>
                        <h3 className="selected-lab-header-info">
                            <span>
                                <PersonIcon style={{ fontSize: "14px" }} />
                                {labPIs[selectedListing.piId]}
                            </span>
                            <span>
                                <AccountBalanceIcon
                                    style={{ fontSize: "14px" }}
                                />
                                {selectedListing.institutionName}
                            </span>
                            <span>
                                <SchoolIcon style={{ fontSize: "14px" }} />
                                {selectedListing.facultyName}
                            </span>
                            <span>
                                <ImportContactsIcon
                                    style={{ fontSize: "14px" }}
                                />
                                {selectedListing.departmentName}
                            </span>
                        </h3>
                    </div>

                    <img
                        src={selectedListing.logo}
                        alt="lab"
                        onClick={() => navigate(`/lab/${selectedListing.id}`)}
                    />
                </div>
                <div className="selected-lab-content">
                    <div className="selected-lab-section">
                        <h4 className="selected-lab-section-title">
                            <BookIcon style={{ fontSize: "20px" }} /> Lab
                            Overview
                        </h4>
                        <div className="selected-lab-section-content">
                            {selectedListing.overview}
                        </div>
                    </div>
                    <div className="selected-lab-section">
                        <h4 className="selected-lab-section-title">
                            <PsychologyIcon style={{ fontSize: "20px" }} />{" "}
                            Areas of Research
                        </h4>
                        <div className="selected-lab-section-content">
                            {selectedListing.keywords.join(", ")}
                        </div>
                    </div>
                </div>
                <div className="browse-labs-footer-buttons">
                    <button
                        className="selected-lab-footer-button"
                        onClick={() => navigate(`/lab/${selectedListing.id}`)}
                    >
                        View Lab
                    </button>

                    {user && !user.labId && (
                        <button
                            className="selected-lab-footer-button"
                            onClick={() => requestJoin(selectedListing)}
                            disabled={
                                !user.emailVerified ||
                                selectedListing.hasRequested
                            }
                        >
                            {user.emailVerified
                                ? selectedListing.hasRequested
                                    ? "Requested"
                                    : "Request to Join"
                                : "Verify Email to Join"}
                        </button>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="browse-labs-page">
            {/* <LabFilter /> */}
            {searchQuery && (
                <div className="browse-labs-header">
                    <h2>
                        18 labs found for{" "}
                        <span style={{ fontWeight: 600 }}>"{searchQuery}"</span>
                    </h2>
                </div>
            )}
            <div className="browse-labs-container">
                <div className="browse-labs-list-area">
                    <Labs />
                </div>
                {selectedListing && (
                    <>
                        <div
                            className={`selected-listing-overlay ${
                                selectedListing ? "active" : ""
                            }`}
                            onClick={() => setSelectedListing(null)}
                        />
                        <div
                            className={`selected-listing-area ${
                                selectedListing ? "active" : ""
                            }`}
                        >
                            <SelectedListing />
                        </div>
                    </>
                )}
            </div>
            <div className="browse-labs-pagination">
                <Pagination className="pagination" count={1} />
            </div>
        </div>
    );
};

export default BrowseLabs;
