import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { roles } from "../../constants/LabConstants";
import { fetchGet } from "../../services/data-service";
import "../../styles/LabProfileTabs/MembersTab.css";

const MemberComponent = ({ member }) => {
    const [profileData, setProfileData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const user = await fetchGet(`/users/${member.id}`, false);
                setProfileData(user);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchProfile();
    }, [member.id]);

    if (!profileData) return null;

    return (
        <div className="lab-profile-content-members-tab-member">
            <div
                className="lab-profile-content-members-tab-member-image"
                onClick={() => navigate(`/profile/${member.id}`)}
            >
                <img
                    src={profileData.profilePicture}
                    alt={`${profileData.firstName} ${profileData.lastName}`}
                />
            </div>
            <h4 onClick={() => navigate(`/profile/${member.id}`)}>
                {profileData.firstName} {profileData.lastName}
            </h4>
            <h5>{roles[member.role]}</h5>
            <p>{profileData.description}</p>
        </div>
    );
};

const MembersTab = () => {
    const { labId } = useParams();
    const [members, setMembers] = useState([]);
    const [groupPicture, setGroupPicture] = useState(null);
    const [principalInvestigator, setPrincipalInvestigator] = useState(null);
    const [groupDescription, setGroupDescription] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchGet(
                    `/labs/${labId}/members?groupDescription=true`,
                    false
                );
                const pi = data.members.find((member) => member.role === "pi");
                const otherMembers = data.members.filter(
                    (member) => member.role !== "pi"
                );

                setPrincipalInvestigator(pi);
                setMembers(otherMembers);
                setGroupPicture(data.groupPicture || "");
                setGroupDescription(data.groupDescription || "");
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [labId]);

    return (
        <div className="lab-profile-content">
            <div className="lab-profile-content-members-section-wrapper">
                <div className="lab-members-header">
                    <h3>Our Team</h3>
                </div>
                <div className="lab-top-section">
                    {groupDescription && (
                        <div className="group-description">
                            <p>{groupDescription}</p>
                        </div>
                    )}
                    {groupPicture && (
                        <div className="lab-group-picture-container">
                            <img
                                src={groupPicture}
                                alt="Lab Group"
                                className="lab-group-picture"
                            />
                        </div>
                    )}
                </div>
                <div className="lab-members-subheader">
                    <h3>Members</h3>
                </div>
                <div className="lab-profile-content-members-tab-members">
                    {principalInvestigator && (
                        <MemberComponent member={principalInvestigator} />
                    )}
                    {members.map((member) => (
                        <MemberComponent key={member.id} member={member} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MembersTab;
