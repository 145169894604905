import { useContext, useState, useEffect } from "react";
import {
    SpecificItem,
    EquipmentRental,
    Service,
    DigitalGood,
} from "./ListingCategories";
import {
    CreateListingContext,
    CreateListingContextProvider,
} from "../context/createlisting-context";

import {
    collection,
    doc,
    getDocs,
    setDoc,
    Timestamp,
} from "firebase/firestore";
import { db, storage } from "../firebase-config";
import { ref, uploadBytes } from "firebase/storage";
import { listingCategories } from "../constants/ListingConstants";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { UserAuth } from "../context/auth-context";
import { useNavigate, useParams } from "react-router-dom";
import { getLabMembersByLabId } from "../firebase/crud";
import "../styles/CreateListingsChooseCat.css";
import digitalGoodDefault from "../assets/digital-good.png";
import specificItemDefault from "../assets/specific-item.png";
import equipmentRentalDefault from "../assets/equipment-rental.png";
import serviceDefault from "../assets/service.png";

const ListingCreation = () => {
    const { labId } = useParams();
    const [labMembers, setLabMembers] = useState([]);

    useEffect(() => {
        const unsub = async () => {
            const members = await getLabMembersByLabId(labId);
            let membersData = members.map((member) => {
                const memberName = member.firstName + " " + member.lastName;
                return {
                    value: memberName,
                    label: memberName,
                    id: member.id,
                };
            });
            setLabMembers(membersData);
        };
        unsub();
    }, []);

    const { step, updateStep, category, updateCategory } =
        useContext(CreateListingContext);

    const handleCategorySelection = (cat) => {
        updateCategory(cat);
        updateStep(step + 1);
    };

    const { user } = UserAuth();
    let navigate = useNavigate();

    const {
        getValues,
        trigger,
        reset,
        formState: { errors },
    } = useFormContext();

    const ChooseListingCategory = () => {
        return (
            step === 1 && (
                <div className="container-categories">
                    <h1 className="title-categories">
                        Which of the following best describe your listing?
                    </h1>
                    <div className="categories">
                        {listingCategories.map((cat, i) => (
                            <div
                                className="category-container"
                                key={cat.id + i}
                                onClick={() => handleCategorySelection(cat.id)}
                            >
                                <img src={cat.image} alt="test" />
                                <input
                                    key={cat.id}
                                    type="radio"
                                    value={cat.id}
                                    name="category"
                                />
                                <div className="category">{cat.value}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        );
    };

    const ListingCategory = () => {
        if (category === "specific-item") {
            return <SpecificItem labMembers={labMembers} />;
        } else if (category === "equipment-rental") {
            return <EquipmentRental labMembers={labMembers} />;
        } else if (category === "service") {
            return <Service labMembers={labMembers} />;
        } else if (category === "digital-good") {
            return <DigitalGood labMembers={labMembers} />;
        } else {
            return null;
        }
    };

    const handleSendData = async () => {
        const uploadFile = (file, id, index) => {
            const imageId = doc(collection(db, "Listings")).id;
            const imageString = `listings/${id}/images/${imageId}`;
            const imageRef = ref(storage, imageString);
            uploadBytes(imageRef, file).then(() => {
                console.log("Image Uploaded");
            });

            return { idx: index, id: imageId, ref: imageString };
        };

        const handleOptionalFields = () => {
            return {
                ...(getValues("fields.price") && {
                    price: getValues("fields.price"),
                }),
                ...(getValues("fields.address") && {
                    address: getValues("fields.address"),
                }),
                ...(getValues("fields.quantity") && {
                    quantity: getValues("fields.quantity"),
                }),
                ...(getValues("fields.retrievalMethods") && {
                    retrievalMethods: getValues("fields.retrievalMethods"),
                }),
                ...(getValues("fields.relevantLink") && {
                    relevantLink: getValues("fields.relevantLink"),
                }),
                ...(getValues("fields.unavailabilities") && {
                    unavailabilities: getValues("fields.unavailabilities"),
                }),
                ...(getValues("fields.licensingType") && {
                    licensingType: getValues("fields.licensingType.value"),
                }),
                ...(getValues("fields.location") && {
                    location: getValues("fields.location"),
                }),
                ...(getValues("fields.delivery") && {
                    delivery: getValues("fields.delivery"),
                }),
            };
        };

        try {
            const listingCollectionRef = collection(db, "Listings");
            const newListingRef = doc(listingCollectionRef);

            const images = getValues("fields.imgs");
            let imageRefs = [];

            if (!images.some((img) => img.file)) {
                let defaultImageSrc;
                switch (category) {
                    case "digital-good":
                        defaultImageSrc = digitalGoodDefault;
                        break;
                    case "specific-item":
                        defaultImageSrc = specificItemDefault;
                        break;
                    case "equipment-rental":
                        defaultImageSrc = equipmentRentalDefault;
                        break;
                    case "service":
                        defaultImageSrc = serviceDefault;
                        break;
                    default:
                        defaultImageSrc = null;
                }

                if (defaultImageSrc) {
                    const response = await fetch(defaultImageSrc);
                    const blob = await response.blob();
                    const defaultFile = new File(
                        [blob],
                        `${category}-default.png`,
                        { type: "image/png" }
                    );

                    imageRefs.push(
                        uploadFile(defaultFile, newListingRef.id, 0)
                    );
                }
            } else {
                images.forEach((img, index) => {
                    if (img.file) {
                        imageRefs.push(
                            uploadFile(img.file, newListingRef.id, index)
                        );
                    }
                });
            }

            const listingData = {
                listerId: user.uid,
                description: getValues("fields.description"),
                labMember: getValues("fields.labMember"),
                labId: labId,
                title: getValues("fields.title"),
                isCollaborative: getValues("fields.isCollaborative"),
                type: category,
                imgRefs: imageRefs,
            };

            const timestamp = Timestamp.now();

            const listingFullData = {
                ...listingData,
                ...handleOptionalFields(),
                createdAt: timestamp,
                updatedAt: timestamp,
            };

            console.log("full data", listingFullData);
            await setDoc(newListingRef, listingFullData);

            alert("Successful");
            let url = `/manager/${labId}`;
            navigate(url);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="container-create-listing">
            <form>
                <ChooseListingCategory />
                <ListingCategory />
                <div
                    className="button-container-listing"
                    style={{
                        justifyContent: "space-between",
                    }}
                >
                    {step > 1 && (
                        <button
                            className="button-listing"
                            type="button"
                            onClick={() => {
                                reset({
                                    fields: {
                                        imgs: [
                                            ...Array(3).fill({
                                                url: null,
                                                file: null,
                                            }),
                                        ],
                                    },
                                });
                                updateStep(step - 1);
                            }}
                        >
                            Back
                        </button>
                    )}
                    {step === 2 && (
                        <button
                            className="button-listing"
                            type="button"
                            onClick={async () => {
                                if (await trigger()) {
                                    handleSendData();
                                } else {
                                    console.log(errors);
                                }
                            }}
                        >
                            Create Listing
                        </button>
                    )}
                </div>
                {/* <div className="progress-bar">
                    <div
                        className="progress"
                        style={{ width: `${(step / 2) * 100}%` }}
                    ></div>
                </div> */}
            </form>
        </div>
    );
};

const CreateListing = () => {
    const methods = useForm();

    useEffect(() => {
        const resetForm = async () => {
            methods.reset({
                fields: {
                    imgs: [
                        ...Array(3).fill({
                            url: null,
                            file: null,
                        }),
                    ],
                },
            });
        };
        resetForm();
    }, [methods]);
    return (
        <CreateListingContextProvider>
            <FormProvider {...methods}>
                <ListingCreation />
            </FormProvider>
        </CreateListingContextProvider>
    );
};

export default CreateListing;
