import { useEffect, useState } from "react";
import { JatsParser, Loading, ModalComponent } from "../components";
import { getDownloadURL, ref } from "firebase/storage";
import { useParams, useNavigate } from "react-router-dom";
import { storage } from "../firebase-config";
import { fetchGet } from "../services/data-service";
import {
    AccountBalance as AccountBalanceIcon,
    School as SchoolIcon,
    Email as EmailIcon,
    FileCopy as FileCopyIcon,
    Check as CheckIcon,
    Share as ShareIcon,
} from "@mui/icons-material";
import QRCode from "qrcode";
import articlePlaceholder from "../assets/article-placeholder.png";
import mcgillLogo from "../assets/McGill_University-logo-0879729A5A-seeklogo.com.png";
import "../styles/Profile.css";

const Profile = () => {
    const { userId } = useParams();
    const navigate = useNavigate();

    const [profile, setProfile] = useState({
        firstName: null,
        lastName: null,
        institutionName: null,
        pictureURL: null,
        email: null,
        googleScholarUrl: null,
    });
    const [lab, setLab] = useState(null);
    const [showAllPublications, setShowAllPublications] = useState(false);
    const [techResourcesIndex, setTechResourcesIndex] = useState(0);
    const [labBannerUrl, setLabBannerUrl] = useState(null);
    const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [linkCopied, setLinkCopied] = useState(false);
    const qrCodeLink = `${window.location.origin}/profile/${userId}`;
    const [listings, setListings] = useState([]);

    const [publications, setPublications] = useState([]);
    const [selectedPublication, setSelectedPublication] = useState(null);
    const [publicationModalOpen, setPublicationModalOpen] = useState(false);

    useEffect(() => {
        const getData = async () => {
            if (userId) {
                const userData = await fetchGet(`/users/${userId}`, false);
                const projects = await fetchGet(
                    `/users/${userId}/projects`,
                    false
                );
                const userPublications = await fetchGet(
                    `/users/${userId}/publications`,
                    false
                );

                if (userData.labId) {
                    const lab = await fetchGet(
                        `/labs/${userData.labId}`,
                        false
                    );
                    const labListings = await fetchGet(
                        `/labs/${userData.labId}/listings`,
                        false
                    );

                    if (lab?.id) {
                        const bannerRef = ref(
                            storage,
                            `labs/${lab.id}/images/banner`
                        );
                        const bannerUrl = await getDownloadURL(bannerRef).catch(
                            () => null
                        );
                        setLabBannerUrl(bannerUrl);
                    }
                    const userListings =
                        labListings?.filter(
                            (listing) => listing.labMember?.id === userId
                        ) || [];
                    setListings(userListings);

                    const techCount = document.querySelector(
                        ".profile-stat-number"
                    );
                    if (techCount) {
                        techCount.textContent = userListings.length || "0";
                    }
                    setLab((prev) => ({
                        ...prev,
                        id: lab.id,
                        name: lab.name,
                        logoURL: lab.logo,
                    }));
                }

                setProfile((prev) => ({
                    ...prev,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    institutionName: userData.institutionName,
                    pictureURL: userData.profilePicture,
                    description: userData.description,
                    profileTags: userData.profileTags || [],
                    projects: projects || [],
                    email: userData.email,
                    googleScholarUrl: userData.googleScholarUrl,
                }));

                setPublications(userPublications || []);
            }
        };

        getData();
    }, [userId]);

    useEffect(() => {
        if (!qrCodeModalOpen) {
            setLinkCopied(false);
        }
    }, [qrCodeModalOpen]);

    const visiblePublications = showAllPublications
        ? publications
        : publications.slice(0, 3);

    const handleSlide = (direction) => {
        if (direction === "left") {
            setTechResourcesIndex((prev) => Math.max(prev - 3, 0));
        } else {
            setTechResourcesIndex((prev) =>
                Math.min(prev + 3, listings.length - 1)
            );
        }
    };

    useEffect(() => {
        // Generate QR code
        const generateQRCode = async () => {
            const qrCode = await QRCode.toDataURL(qrCodeLink);
            setQrCode(qrCode);
        };
        generateQRCode();
    }, [userId]);

    const PublicationDetails = ({ publication }) => {
        return (
            <>
                <article className="lab-profile-publication-item">
                    <h2 className="lab-profile-publication-title">
                        {publication.title}
                    </h2>
                    <div className="lab-profile-publication-authors">
                        {publication.authors.join(", ")}
                    </div>
                    {publication.images && publication.images.length > 0 && (
                        <div className="lab-profile-publication-image">
                            <img
                                src={publication.images[0]}
                                alt="Publication"
                            />
                        </div>
                    )}
                    <p className="lab-profile-publication-description">
                        <JatsParser>{publication.description}</JatsParser>
                    </p>
                    <div className="lab-profile-publication-meta">
                        <div className="lab-profile-publication-date-journal">
                            Published on {publication.publicationDate} in
                            <span className="lab-profile-publication-journal">
                                {publication.journal}
                            </span>
                        </div>
                        <div className="lab-profile-publication-links">
                            <a
                                href={`https://doi.org/${publication.doi}`}
                                className="lab-profile-publication-doi"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                DOI: {publication.doi}
                            </a>
                            <div className="lab-profile-publications-editor-left-buttons">
                                <a
                                    href={publication.htmlLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Article HTML
                                </a>
                            </div>
                        </div>
                    </div>
                </article>
            </>
        );
    };

    if (!profile.firstName || !profile.lastName) {
        return <Loading />;
    }

    return (
        <div className="profile-container">
            <div className="lab-profile-banner">
                <div className="lab-profile-banner-ellipses">
                    <div className="lab-profile-banner-ellipse-1" />
                    <div className="lab-profile-banner-ellipse-2" />
                </div>
                <div
                    className="lab-profile-image-banner"
                    style={
                        labBannerUrl
                            ? { backgroundImage: `url(${labBannerUrl})` }
                            : {} // You might want to add a default background style here
                    }
                />
            </div>
            <div className="profile">
                <div className="profile-header">
                    <div className="profile-pic">
                        <img
                            src={profile.pictureURL}
                            alt={`${profile.firstName} ${profile.lastName}`}
                            onError={(e) => {
                                e.target.style.display = "none";
                            }}
                        />
                    </div>
                    <div className="profile-info">
                        <div className="profile-info-top">
                            <div className="profile-name-role">
                                <div className="profile-name">
                                    {profile.firstName} {profile.lastName}
                                    <div className="profile-contact-icons">
                                        <a
                                            href={`mailto:${profile.email}`}
                                            className="profile-icon"
                                        >
                                            <EmailIcon />
                                        </a>
                                        <a
                                            href={`http://scholar.google.co.uk/scholar?as_q=&num=10&btnG=Search+Scholar&as_epq=&as_oq=&as_eq=&as_occt=any&as_sauthors=%22${profile.firstName}%20${profile.lastName}%22&as_publication=&as_ylo=&as_yhi=&as_allsubj=all&hl=en`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="profile-icon"
                                        >
                                            <SchoolIcon />
                                        </a>
                                        <a
                                            onClick={() =>
                                                setQrCodeModalOpen(true)
                                            }
                                            className="profile-icon"
                                        >
                                            <ShareIcon />
                                        </a>
                                    </div>
                                </div>
                                {/* Other elements in profile-name-role, if any */}
                            </div>
                            <div className="profile-roles">
                                {profile.profileTags.map((tag) => (
                                    <span className="profile-role">{tag}</span>
                                ))}
                            </div>
                        </div>
                        <div className="profile-info-bottom">
                            <div className="profile-field-container">
                                {profile.institutionName && (
                                    <div
                                        className="profile-field"
                                        onClick={() =>
                                            navigate(
                                                `/labs?institution=${encodeURIComponent(
                                                    profile.institutionName
                                                )}`
                                            )
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div className="profile-field-icon">
                                            {profile.institutionName
                                                .toLowerCase()
                                                .includes("mcgill") ? (
                                                <img
                                                    src={mcgillLogo}
                                                    alt="McGill University"
                                                />
                                            ) : (
                                                <SchoolIcon />
                                            )}
                                        </div>
                                        <div className="profile-field-text">
                                            <span>Institution</span>
                                            {profile.institutionName}
                                        </div>
                                    </div>
                                )}
                                {lab && (
                                    <div
                                        className="profile-field"
                                        onClick={() =>
                                            navigate(`/lab/${lab.id}`)
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div className="profile-field-icon">
                                            {lab.logoURL ? (
                                                <img
                                                    src={lab.logoURL}
                                                    alt="Lab"
                                                />
                                            ) : (
                                                <AccountBalanceIcon />
                                            )}
                                        </div>
                                        <div className="profile-field-text">
                                            <span>Lab</span>
                                            {lab.name}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="profile-stats">
                        <div className="profile-stat-card">
                            <div className="profile-stat-number">
                                {listings.length}
                            </div>
                            <div className="profile-stat-label">
                                Technologies
                            </div>
                        </div>
                        <div className="profile-stat-card">
                            <div className="profile-stat-number">N/A</div>
                            <div className="profile-stat-label">
                                Collaborations
                            </div>
                        </div>
                        <div className="profile-stat-card">
                            <div className="profile-stat-number">
                                {publications.length}
                            </div>
                            <div className="profile-stat-label">
                                Publications
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-content">
                    {profile.description && (
                        <>
                            <h1>Personal Description</h1>
                            <div className="profile-content-section">
                                {profile.description}
                            </div>
                        </>
                    )}

                    {profile.projects && profile.projects.length > 0 && (
                        <>
                            <h1>Project Description</h1>
                            {profile.projects.map((project, index) => (
                                <div key={index} className="profile-project">
                                    <h2>{project.title}</h2>
                                    <div className="profile-content-section">
                                        <p>{project.content}</p>
                                        <div className="profile-project-images">
                                            {project.files &&
                                                project.files.map(
                                                    (file, fileIndex) => (
                                                        <div
                                                            key={fileIndex}
                                                            className="profile-project-image-container"
                                                        >
                                                            <img
                                                                src={file.url}
                                                                alt={`Project image ${
                                                                    fileIndex +
                                                                    1
                                                                }`}
                                                                className="profile-project-image"
                                                            />
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}

                    {listings && listings.length > 0 && (
                        <>
                            <h1>Associated Technologies and Resources</h1>
                            <div className="associated-tech-resources">
                                {techResourcesIndex > 0 && (
                                    <div
                                        className="slider-arrow slider-arrow-left"
                                        onClick={() => handleSlide("left")}
                                    >
                                        &#8592;
                                    </div>
                                )}
                                <div
                                    className="tech-resources-slider"
                                    style={{
                                        transform: `translateX(-${
                                            techResourcesIndex * (100 / 3)
                                        }%)`,
                                    }}
                                >
                                    {listings.map((listing, index) => (
                                        <div
                                            key={index}
                                            className="tech-resource-card"
                                            onClick={() =>
                                                navigate(
                                                    `/listing/${listing.id}`
                                                )
                                            }
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="tech-resource-image">
                                                <img
                                                    src={listing.img}
                                                    alt={listing.title}
                                                    className="tech-resource-image"
                                                />
                                            </div>
                                            <div className="tech-resource-content">
                                                <h3>{listing.title}</h3>
                                                <p>{listing.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {techResourcesIndex < listings.length - 1 && (
                                    <div
                                        className="slider-arrow slider-arrow-right"
                                        onClick={() => handleSlide("right")}
                                    >
                                        &#8594;
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {publications && publications.length > 0 && (
                        <>
                            <h1>Publications</h1>
                            <div className="publications-section">
                                {visiblePublications.map((pub, index) => (
                                    <div
                                        key={index}
                                        className="publication-card"
                                        onClick={() => {
                                            setSelectedPublication(pub);
                                            setPublicationModalOpen(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {pub.images && pub.images.length > 0 ? (
                                            <img
                                                src={pub.images[0]}
                                                alt={pub.title}
                                                className="profile-publication-image"
                                            />
                                        ) : (
                                            <img
                                                src={articlePlaceholder}
                                                alt={pub.title}
                                                className="profile-publication-image"
                                            />
                                        )}

                                        <div className="publication-details">
                                            <div>
                                                <h3>{pub.title}</h3>
                                                <p>{pub.authors.join(", ")}</p>
                                                <p>
                                                    <JatsParser>
                                                        {pub.description}
                                                    </JatsParser>
                                                </p>
                                                <p>
                                                    Published on{" "}
                                                    {pub.publicationDate} in
                                                    <span className="publication-journal">
                                                        {pub.journal}
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="publication-bottom">
                                                <a
                                                    href={`https://doi.org/${pub.doi}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="doi"
                                                >
                                                    DOI: {pub.doi}
                                                </a>
                                                <div className="publication-actions">
                                                    <a
                                                        href={pub.htmlLink}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="html-button"
                                                    >
                                                        Article HTML
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {!showAllPublications &&
                                publications.length > 3 && (
                                    <button
                                        className="view-all-button"
                                        onClick={() =>
                                            setShowAllPublications(true)
                                        }
                                    >
                                        View more research (
                                        {publications.length})
                                    </button>
                                )}
                            {showAllPublications && (
                                <button
                                    className="view-less-button"
                                    onClick={() =>
                                        setShowAllPublications(false)
                                    }
                                >
                                    View less
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>

            <ModalComponent
                title="Profile QR Code"
                isOpen={qrCodeModalOpen}
                toggle={() => setQrCodeModalOpen(!qrCodeModalOpen)}
                submitText={
                    linkCopied ? (
                        <CheckIcon style={{ fontSize: "16px" }} />
                    ) : (
                        <FileCopyIcon style={{ fontSize: "16px" }} />
                    )
                }
                onSubmit={() => {
                    if (!linkCopied) {
                        navigator.clipboard.writeText(qrCodeLink);
                        setLinkCopied(true);
                    } else {
                        setLinkCopied(false);
                    }
                }}
            >
                <div className="profile-qr-code-container">
                    <img
                        className="profile-qr-code-image"
                        src={qrCode}
                        alt="Profile QR Code"
                    />
                    <div className="profile-qr-code-input-container">
                        <label htmlFor="qr-code-input">Copy Link</label>
                        <input
                            type="text"
                            id="qr-code-input"
                            value={qrCodeLink}
                            readOnly
                        />
                    </div>
                </div>
            </ModalComponent>

            <ModalComponent
                isOpen={publicationModalOpen}
                toggle={() => setPublicationModalOpen(false)}
            >
                {selectedPublication && (
                    <PublicationDetails publication={selectedPublication} />
                )}
            </ModalComponent>
        </div>
    );
};

export default Profile;
