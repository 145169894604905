import React, { useRef, useState, useEffect } from "react";
import Select from "react-select";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ErrorMessages } from "../../helpers/ListingHelpers";
import { Loading, SelectPricing, SelectUnit } from "../../components";
import { unitsToJson } from "../../helpers/ListingHelpers";
import {
    quantityUnits,
    licensingTypes,
} from "../../constants/ListingConstants";
import imagePlaceholder from "../../assets/image-placeholder.png";
import { Close as CloseIcon } from "@mui/icons-material";
import "../../styles/CreateListingStyles.css";
import { getLabById } from "../../firebase/crud";
import { useParams } from "react-router-dom";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import "../../styles/CreateListingStyles.css";

const SpecificItem = ({ labMembers, listing }) => {
    const { labId } = useParams();
    const [lab, setLab] = useState(null);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const {
        register,
        control,
        getValues,
        formState: { errors, defaultValues },
        watch,
        setValue,
        reset,
    } = useFormContext();

    const delivery = useWatch({
        name: "fields.delivery",
        control,
        defaultValue: defaultValues?.fields?.delivery || "pickup",
    });

    const pageRef = {
        about: useRef(null),
        pricing: useRef(null),
        delivery: useRef(null),
        misc: useRef(null),
    };

    const handleImageChange = (file, index) => {
        const currentImages = getValues("fields.imgs");
        const updatedImages = [...currentImages];
        updatedImages[index] = {
            url: URL.createObjectURL(file),
            file,
            idx: index,
        };
        setValue("fields.imgs", updatedImages, { shouldDirty: true });
        console.log("updatedImages", updatedImages);
    };

    const handleImageDelete = (index) => {
        const currentImages = getValues("fields.imgs");
        const updatedImages = [...currentImages];
        updatedImages[index] = { url: null, file: null, idx: index };
        setValue("fields.imgs", updatedImages, { shouldDirty: true });
        console.log("updatedImages", updatedImages);
    };

    useEffect(() => {
        const setListingValues = async () => {
            if (listing) {
                reset({
                    fields: {
                        title: listing.title,
                        description: listing.description,
                        labMember: listing.labMember,
                        licensingType: {
                            value: listing.licensingType,
                            label: listing.licensingType,
                        },
                        relevantLink: listing.relevantLink,
                        isCollaborative: listing.isCollaborative,
                        imgs: [
                            ...listing.imgs,
                            ...Array(3)
                                .fill(null)
                                .map((_, idx) => ({
                                    url: null,
                                    file: null,
                                    idx: listing.imgs.length + idx,
                                })),
                        ].slice(0, 3),

                        ...(listing.isCollaborative && {
                            price: {
                                value: listing.price?.value,
                                units: listing.price?.units,
                                customUnits: listing.price?.customUnits,
                            },
                            quantity: listing.quantity,
                            delivery: listing.delivery || "pickup",
                            location: listing.location || "",
                        }),
                    },
                });
            }
        };
        setListingValues();
    }, [listing]);

    useEffect(() => {
        getLabById(labId).then((lab) => setLab(lab));
    }, [labId]);

    const isCollaborationAllowed = watch("fields.isCollaborative");

    if (!getValues("fields.imgs")) {
        return <Loading />;
    }

    return (
        // <LoadScript
        //     googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        //     libraries={libraries}
        //     onLoad={() => setIsScriptLoaded(true)}
        // >

        <>
            <h1 className="create-listing-header">Specific Item</h1>
            <div className="create-listing-container">
                <div className="create-listing-content">
                    <div ref={pageRef.about} className="create-listing-section">
                        <h2 className="create-listing-subheader">
                            Item Overview
                        </h2>
                        <div className="create-listing-field">
                            <h3>
                                Item Name
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                This is the name of the item that will be sold.
                            </h4>
                            <input
                                className={
                                    errors?.fields?.title
                                        ? "input input-error"
                                        : "input"
                                }
                                type="text"
                                name="title"
                                id="title"
                                placeholder="Title"
                                {...register("fields.title", {
                                    required: true,
                                })}
                            />
                            {errors?.fields?.title && (
                                <ErrorMessages message="The above field is required." />
                            )}
                        </div>
                        <div className="create-listing-field">
                            <h3>
                                Item Description
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                Describe your item in terms of functionality,
                                volume, size, weight, etc.
                            </h4>
                            <textarea
                                className={
                                    errors?.fields?.description
                                        ? "input input-error"
                                        : "input"
                                }
                                name="description"
                                id="description"
                                placeholder="Description"
                                {...register("fields.description", {
                                    required: true,
                                })}
                            />
                            {errors?.fields?.description && (
                                <ErrorMessages message="The above field is required." />
                            )}
                        </div>
                        <div className="create-listing-field">
                            <h3>
                                Lab Member in charge
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                The lab member will be in charge of managing the
                                item.
                            </h4>
                            <Controller
                                name="fields.labMember"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...field}
                                            className={
                                                errors?.fields?.labMember
                                                    ? "input-error labmember-select"
                                                    : "labmember-select"
                                            }
                                            options={labMembers}
                                            isSearchable
                                            placeholder="Assign lab member"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                        />
                                        {errors?.fields?.labMember && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className="create-listing-field">
                            <h3>
                                Licensing Type
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>Select the type of licensing for the item.</h4>
                            <Controller
                                name="fields.licensingType"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...field}
                                            className={
                                                errors?.fields?.licensingType
                                                    ? "input-error licensing-select"
                                                    : "licensing-select"
                                            }
                                            options={licensingTypes}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                        />
                                        {errors?.fields?.licensingType && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className="create-listing-field">
                            <h3>Collaboration</h3>
                            <h4>
                                Collaborative items will be shown in the
                                marketplace.
                            </h4>
                            <Controller
                                name="fields.isCollaborative"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <FormControlLabel
                                        className="checkbox-label"
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value || false}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        e.target.checked
                                                    )
                                                }
                                                style={{
                                                    marginRight: "5px",
                                                    paddingRight: "0",
                                                    paddingTop: "0",
                                                    paddingBottom: "0",
                                                }}
                                            />
                                        }
                                        label="Allow collaboration"
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div ref={pageRef.misc} className="create-listing-section">
                        <h2 className="create-listing-subheader">
                            Miscellaneous
                        </h2>
                        <div className="create-listing-field">
                            <h3>Listing Images</h3>
                            <h4>
                                Add an image of the item that will be sold. You
                                can add up to 3 images.
                            </h4>
                            <div className="image-input-container">
                                {watch("fields.imgs").map(({ url }, index) => (
                                    <label
                                        key={index}
                                        className="image-upload-label"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <input
                                            placeholder={imagePlaceholder}
                                            type="file"
                                            accept="image/*"
                                            {...register(
                                                `fields.imgs[${index}]`,
                                                {
                                                    onChange: (e) => {
                                                        handleImageChange(
                                                            e.target.files[0],
                                                            index
                                                        );
                                                    },
                                                }
                                            )}
                                            style={{ display: "none" }}
                                        />
                                        <div
                                            className="image-upload-square"
                                            style={{
                                                backgroundImage: `url(${
                                                    url || imagePlaceholder
                                                })`,
                                            }}
                                        />
                                        {index === 0 && (
                                            <span className="image-label">
                                                Primary
                                            </span>
                                        )}
                                        {url && (
                                            <button
                                                className="delete-image"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleImageDelete(index);
                                                }}
                                            >
                                                <CloseIcon
                                                    style={{
                                                        fontSize: "16px",
                                                    }}
                                                />
                                            </button>
                                        )}
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div className="create-listing-field">
                            <h3>Relevant Link</h3>
                            <h4>
                                Add a link to a relevant website, article,
                                publication, etc.
                            </h4>
                            <input
                                className={
                                    errors?.fields?.relevantLink
                                        ? "input input-error"
                                        : "input"
                                }
                                type="text"
                                name="relevantLink"
                                id="relevantLink"
                                placeholder="Relevant Link"
                                {...register("fields.relevantLink", {
                                    pattern:
                                        //eslint-disable-next-line
                                        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g,
                                })}
                            />
                            {errors?.fields?.relevantLink && (
                                <ErrorMessages message="The above link is invalid." />
                            )}
                        </div>
                    </div>

                    {isCollaborationAllowed && (
                        <>
                            <div
                                ref={pageRef.pricing}
                                className="create-listing-section"
                            >
                                <h2 className="create-listing-subheader">
                                    Pricing & Inventory
                                </h2>
                                <div className="create-listing-field">
                                    <h3>
                                        Price
                                        <span style={{ color: "red" }}>*</span>
                                    </h3>
                                    <h4>Set the price of the item.</h4>
                                    <div className="price-quantity-container">
                                        <SelectPricing
                                            isError={
                                                errors?.fields?.price?.value
                                            }
                                            fieldName="fields.price.value"
                                            required={isCollaborationAllowed}
                                        />
                                        <div className="price-per-text">
                                            per
                                        </div>
                                        <SelectUnit
                                            isError={
                                                errors?.fields?.price?.units
                                            }
                                            isCustomError={
                                                errors?.fields?.price
                                                    ?.customUnits
                                            }
                                            fieldName="fields.price.units"
                                            customFieldName="fields.price.customUnits"
                                            unitOptions={unitsToJson(
                                                quantityUnits
                                            )}
                                            required={isCollaborationAllowed}
                                        />
                                    </div>
                                    {(errors?.fields?.price?.value ||
                                        errors?.fields?.price?.units ||
                                        errors?.fields?.price?.customUnits) && (
                                        <ErrorMessages message="The above field is required." />
                                    )}
                                </div>
                                <div className="create-listing-field">
                                    <h3>Available Quantity</h3>
                                    <h4>
                                        Display the quantity of the item that is
                                        available for purchase.
                                    </h4>
                                    <div className="price-quantity-container">
                                        <input
                                            {...register("fields.quantity", {
                                                valueAsNumber: true,
                                            })}
                                            className="input"
                                            type="number"
                                            min="0"
                                            id="availableQuantity"
                                            placeholder="Available Quantity"
                                        />
                                    </div>
                                </div>
                                <div className="create-listing-field">
                                    <h3>Direct Buying</h3>
                                    <h4>
                                        Allow clients to directly purchase
                                        without going through the lab manager's
                                        approval.
                                    </h4>
                                    <FormControlLabel
                                        className="checkbox-label"
                                        control={
                                            <Checkbox
                                                style={{
                                                    marginRight: "5px",
                                                    paddingRight: "0",
                                                    paddingTop: "0",
                                                    paddingBottom: "0",
                                                }}
                                                name="directBuying"
                                                id="directBuying"
                                                {...register(
                                                    "fields.directBuying"
                                                )}
                                            />
                                        }
                                        label="Allow Direct Buying"
                                    />
                                </div>
                                {/* <div className="create-listing-field">
                                    <h3>Item Variations</h3>
                                    <h4>
                                        Add variants of the item that will be
                                        sold. Prices and quantity can be varied.
                                    </h4>
                                    <CustomSpecifications />
                                </div> */}
                            </div>
                            <div
                                ref={pageRef.delivery}
                                className="create-listing-section"
                            >
                                <h2 className="create-listing-subheader">
                                    Delivery & Shipping
                                </h2>

                                <div className="create-listing-field">
                                    <h3>
                                        Delivery Options
                                        <span style={{ color: "red" }}>*</span>
                                    </h3>
                                    <h4>
                                        Select the delivery methods offered for
                                        the item.
                                    </h4>
                                    <RadioGroup defaultValue="pickup" row>
                                        <FormControlLabel
                                            className="delivery-option"
                                            control={
                                                <Controller
                                                    name="fields.delivery"
                                                    defaultValue="pickup"
                                                    control={control}
                                                    rules={{
                                                        required:
                                                            "Lab location is required",
                                                    }}
                                                    render={({ field }) => (
                                                        <Radio
                                                            {...field}
                                                            value="pickup"
                                                            style={{
                                                                color: "var(--primary-color)",
                                                            }}
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            }
                                            label="Pick up from lab"
                                        />
                                        <FormControlLabel
                                            className="delivery-option"
                                            control={
                                                <Controller
                                                    name="fields.delivery"
                                                    control={control}
                                                    defaultValue="pickup"
                                                    rules={{
                                                        required:
                                                            "Lab location is required",
                                                    }}
                                                    render={({ field }) => (
                                                        <Radio
                                                            {...field}
                                                            value="ship"
                                                            style={{
                                                                color: "var(--primary-color)",
                                                            }}
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            }
                                            label="Ship from lab"
                                        />
                                        <FormControlLabel
                                            className="delivery-option"
                                            value="both"
                                            control={
                                                <Controller
                                                    name="fields.delivery"
                                                    control={control}
                                                    defaultValue="pickup"
                                                    render={({ field }) => (
                                                        <Radio
                                                            {...field}
                                                            value="both"
                                                            style={{
                                                                color: "var(--primary-color)",
                                                            }}
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            }
                                            label="Both"
                                        />
                                    </RadioGroup>
                                </div>

                                {(delivery === "pickup" ||
                                    delivery === "both") && (
                                    <div className="create-listing-field">
                                        <h3>Pickup Location</h3>
                                        <h4>
                                            Select the location where the item
                                            will be picked up.
                                        </h4>
                                        {/* <StandaloneSearchBox> */}
                                        <input
                                            defaultValue={lab?.addresses?.lab}
                                            className="input"
                                            type="text"
                                            placeholder="Lab Location"
                                            {...register("fields.location")}
                                        />
                                        {/* </StandaloneSearchBox> */}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default SpecificItem;
