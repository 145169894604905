import { useState, useEffect } from "react";
import {
    collection,
    doc,
    setDoc,
    deleteDoc,
    getDocs,
    query,
    where,
} from "firebase/firestore";
import { db, storage } from "../../firebase-config";
import {
    ref,
    uploadBytes,
    getDownloadURL,
    deleteObject,
} from "firebase/storage";
import { useParams } from "react-router-dom";
import { UserAuth } from "../../context/auth-context";
import { ModalComponent, Loading } from "../../components";
import {
    Add as AddIcon,
    AddPhotoAlternate as AddPhotoAlternateIcon,
    ArrowBackIosNew as ArrowBackIosNewIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    Close as CloseIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Link as LinkIcon,
} from "@mui/icons-material";
import "../../styles/LabManagerTabs/NewsManager.css";

const NewsManager = () => {
    const { labId } = useParams();
    const { user } = UserAuth();
    const [newsItems, setNewsItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [newNewsItem, setNewNewsItem] = useState({
        id: "",
        title: "",
        content: "",
        images: [],
    });
    const [currentNewsItem, setCurrentNewsItem] = useState(null);
    const [linkModalOpen, setLinkModalOpen] = useState(false);
    const [linkDetails, setLinkDetails] = useState({ text: "", url: "" });
    const [selectionRange, setSelectionRange] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [newsToDelete, setNewsToDelete] = useState(null);

    useEffect(() => {
        fetchNews();
    }, [labId]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const fetchNews = async () => {
        try {
            const newsCollection = collection(db, "News");
            const q = query(newsCollection, where("labId", "==", labId));
            const newsSnapshot = await getDocs(q);
            const newsData = await Promise.all(
                newsSnapshot.docs.map(async (doc) => {
                    const data = doc.data();
                    if (data.imageRef) {
                        const imageUrl = await getDownloadURL(
                            ref(storage, data.imageRef)
                        );
                        return { id: doc.id, ...data, imageUrl };
                    }
                    return { id: doc.id, ...data };
                })
            );
            const sortedNewsData = newsData.sort((a, b) => {
                const dateA = a.timestamp?.toDate?.() || new Date(a.timestamp);
                const dateB = b.timestamp?.toDate?.() || new Date(b.timestamp);
                return dateB - dateA;
            });
            setNewsItems(sortedNewsData);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching news:", error);
            setLoading(false);
        }
    };

    const handleAddNews = () => {
        setCurrentNewsItem(null);
        setIsModalOpen(true);
    };

    const handleEditNews = (newsItem) => {
        const formattedImages = (newsItem.imageUrls || []).map((url) => ({
            url: url,
        }));

        setCurrentNewsItem({
            ...newsItem,
            images: formattedImages,
        });
        setIsModalOpen(true);
    };

    const handleDeleteNews = (newsItem) => {
        setNewsToDelete(newsItem);
        setDeleteModalOpen(true);
    };

    const confirmDelete = async () => {
        try {
            const id = newsToDelete.id;
            const newsRef = doc(db, "News", id);
            await deleteDoc(newsRef);

            // Delete all images if they exist
            if (newsToDelete.imageRefs && newsToDelete.imageRefs.length > 0) {
                for (const imageRef of newsToDelete.imageRefs) {
                    const storageRef = ref(storage, imageRef);
                    await deleteObject(storageRef);
                }
            }

            setNewsItems(newsItems.filter((item) => item.id !== id));
            setDeleteModalOpen(false);
            setNewsToDelete(null);
        } catch (error) {
            console.error("Error deleting news:", error);
        }
    };

    const handleImageUpload = (e, newsItem, setNewsItem) => {
        const files = Array.from(e.target.files);
        const currentImages = newsItem.images || [];

        if (currentImages.length + files.length > 9) {
            alert("Maximum 9 images allowed");
            return;
        }

        const newImages = files.map((file) => ({
            file,
            url: URL.createObjectURL(file),
        }));

        setNewsItem({
            ...newsItem,
            images: [...currentImages, ...newImages],
        });
    };

    const handleSaveNews = async (id, newsItem = null) => {
        const itemToSave = newsItem || newsItems.find((item) => item.id === id);
        const newsRef = doc(db, "News", id);

        try {
            let imageRefs = itemToSave.imageRefs || [];
            let imageUrls = [];

            // Handle multiple images
            if (itemToSave.images && itemToSave.images.length > 0) {
                for (let i = 0; i < itemToSave.images.length; i++) {
                    const image = itemToSave.images[i];
                    if (image.file) {
                        const imageRef = `news/${id}/image_${i}`;
                        const storageRef = ref(storage, imageRef);
                        await uploadBytes(storageRef, image.file);
                        const imageUrl = await getDownloadURL(storageRef);
                        imageRefs.push(imageRef);
                        imageUrls.push(imageUrl);
                    } else {
                        imageUrls.push(image.url);
                    }
                }
            }

            const newsData = {
                title: itemToSave.title,
                content: itemToSave.content,
                timestamp: itemToSave.timestamp || new Date(),
                labId: labId,
                type: "news",
                author: user.firstName + " " + user.lastName,
                authorId: user.uid,
                imageRefs: imageRefs,
                imageUrls: imageUrls,
            };

            await setDoc(newsRef, newsData);

            return { ...newsData, id, imageUrls };
        } catch (error) {
            console.error("Error saving news:", error);
            throw error;
        }
    };

    const handleDeleteImage = (id) => {
        setNewsItems(
            newsItems.map((item) =>
                item.id === id
                    ? {
                          ...item,
                          imageFile: null,
                          imageUrl: null,
                          imageRef: null,
                      }
                    : item
            )
        );
    };

    const handleModalSubmit = async () => {
        try {
            if (currentNewsItem) {
                await handleSaveNews(currentNewsItem.id, currentNewsItem);
            } else {
                const newsId = doc(collection(db, "News")).id;
                const newsItemWithId = { ...newNewsItem, id: newsId };
                await handleSaveNews(newsId, newsItemWithId);
            }

            // Refetch news after saving
            await fetchNews();

            // Reset form state
            setIsModalOpen(false);
            setNewNewsItem({
                id: "",
                title: "",
                content: "",
                images: [],
            });
            setCurrentNewsItem(null);
        } catch (error) {
            console.error("Error saving news:", error);
        }
    };

    const handleAddLink = () => {
        const textarea = document.querySelector(".news-item-editor textarea");
        const selectedText = textarea.value.substring(
            textarea.selectionStart,
            textarea.selectionEnd
        );

        if (selectedText) {
            setSelectionRange({
                start: textarea.selectionStart,
                end: textarea.selectionEnd,
            });
            setLinkDetails({ text: selectedText, url: "" });
            setLinkModalOpen(true);
        } else {
            alert("Please select some text to add a link");
        }
    };

    const handleLinkSubmit = () => {
        if (linkDetails.url) {
            const linkMarkdown = `[${linkDetails.text}](${linkDetails.url})`;
            const content = currentNewsItem
                ? currentNewsItem.content
                : newNewsItem.content;

            const newContent =
                content.substring(0, selectionRange.start) +
                linkMarkdown +
                content.substring(selectionRange.end);

            if (currentNewsItem) {
                setCurrentNewsItem({ ...currentNewsItem, content: newContent });
            } else {
                setNewNewsItem({ ...newNewsItem, content: newContent });
            }
        }
        setLinkModalOpen(false);
    };

    const ImageCarousel = ({ images }) => {
        const [currentIndex, setCurrentIndex] = useState(0);

        if (!images || images.length === 0) return null;
        if (images.length === 1) {
            return (
                <div className="news-item-image-container">
                    <img src={images[0]} alt="" className="carousel-image" />
                </div>
            );
        }

        return (
            <div className="carousel-container">
                <button
                    className="carousel-arrow left"
                    onClick={() =>
                        setCurrentIndex((prev) =>
                            prev === 0 ? images.length - 1 : prev - 1
                        )
                    }
                >
                    <ArrowBackIosNewIcon />
                </button>
                <img
                    src={images[currentIndex]}
                    alt=""
                    className="carousel-image"
                />
                <button
                    className="carousel-arrow right"
                    onClick={() =>
                        setCurrentIndex((prev) =>
                            prev === images.length - 1 ? 0 : prev + 1
                        )
                    }
                >
                    <ArrowForwardIosIcon />
                </button>
            </div>
        );
    };

    const handleCloseEditor = () => {
        setIsModalOpen(false);
        setCurrentNewsItem(null);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="news-manager">
            <div className="news-manager-header">
                <h1 className="listings-manager-title">Lab News</h1>
                <button
                    className="news-manager-add-button"
                    onClick={handleAddNews}
                >
                    <AddIcon /> Add News
                </button>
            </div>

            <ModalComponent
                title="Confirm Delete"
                isOpen={deleteModalOpen}
                toggle={() => {
                    setDeleteModalOpen(false);
                    setNewsToDelete(null);
                }}
                submitText="Delete"
                onSubmit={confirmDelete}
                isDeleteModal={true}
            >
                <div className="delete-confirmation-content">
                    <p>Are you sure you want to delete this news item?</p>
                    <p className="delete-title">"{newsToDelete?.title}"</p>
                    <p className="delete-warning">
                        This action cannot be undone.
                    </p>
                </div>
            </ModalComponent>

            {!isMobile ? (
                <ModalComponent
                    title={currentNewsItem ? "Edit News" : "Add News"}
                    isOpen={isModalOpen}
                    toggle={handleCloseEditor}
                    submitText="Save News"
                    onSubmit={handleModalSubmit}
                >
                    <NewsEditorContent
                        currentNewsItem={currentNewsItem}
                        newNewsItem={newNewsItem}
                        setCurrentNewsItem={setCurrentNewsItem}
                        setNewNewsItem={setNewNewsItem}
                        handleImageUpload={handleImageUpload}
                        handleAddLink={handleAddLink}
                    />
                </ModalComponent>
            ) : (
                <>
                    <div
                        className={`news-editor-overlay ${
                            currentNewsItem || isModalOpen ? "active" : ""
                        }`}
                        onClick={handleCloseEditor}
                    />
                    <div
                        className={`news-editor-slideup ${
                            currentNewsItem || isModalOpen ? "active" : ""
                        }`}
                    >
                        <div className="news-editor-card">
                            <button
                                className="news-editor-close-button"
                                onClick={handleCloseEditor}
                            >
                                ×
                            </button>
                            <h2>
                                {currentNewsItem ? "Edit News" : "Add News"}
                            </h2>
                            <NewsEditorContent
                                currentNewsItem={currentNewsItem}
                                newNewsItem={newNewsItem}
                                setCurrentNewsItem={setCurrentNewsItem}
                                setNewNewsItem={setNewNewsItem}
                                handleImageUpload={handleImageUpload}
                                handleAddLink={handleAddLink}
                            />
                            <div className="news-editor-footer">
                                <button onClick={handleModalSubmit}>
                                    Save News
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <ModalComponent
                title="Add Link"
                isOpen={linkModalOpen}
                toggle={() => setLinkModalOpen(false)}
                submitText="Insert Link"
                onSubmit={handleLinkSubmit}
            >
                <div className="link-modal-content">
                    <input
                        type="text"
                        placeholder="Link Text"
                        value={linkDetails.text}
                        onChange={(e) =>
                            setLinkDetails({
                                ...linkDetails,
                                text: e.target.value,
                            })
                        }
                    />
                    <input
                        type="url"
                        placeholder="URL (https://...)"
                        value={linkDetails.url}
                        onChange={(e) =>
                            setLinkDetails({
                                ...linkDetails,
                                url: e.target.value,
                            })
                        }
                    />
                </div>
            </ModalComponent>

            <div className="news-manager-content">
                {newsItems.map((item) => (
                    <div key={item.id} className="news-item">
                        <div className="news-item-display">
                            <h3>{item.title}</h3>
                            <p>{item.content}</p>
                            <ImageCarousel
                                images={
                                    item.imageUrls ||
                                    (item.imageUrl ? [item.imageUrl] : [])
                                }
                            />
                            <div className="news-item-actions">
                                <span className="news-item-date">
                                    {new Date(
                                        item.timestamp?.toDate?.() ||
                                            item.timestamp
                                    ).toLocaleDateString()}
                                </span>
                                <div className="action-buttons">
                                    <button
                                        onClick={() => handleEditNews(item)}
                                    >
                                        <EditIcon /> Edit
                                    </button>
                                    <button
                                        onClick={() => handleDeleteNews(item)}
                                    >
                                        <DeleteIcon /> Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Extract editor content to avoid duplication
const NewsEditorContent = ({
    currentNewsItem,
    newNewsItem,
    setCurrentNewsItem,
    setNewNewsItem,
    handleImageUpload,
    handleAddLink,
}) => (
    <div className="news-item-editor">
        <input
            type="text"
            placeholder="News Title"
            value={currentNewsItem ? currentNewsItem.title : newNewsItem.title}
            onChange={(e) => {
                const title = e.target.value;
                if (currentNewsItem) {
                    setCurrentNewsItem({ ...currentNewsItem, title });
                } else {
                    setNewNewsItem({ ...newNewsItem, title });
                }
            }}
        />
        <div className="textarea-container">
            <textarea
                placeholder="News Content"
                value={
                    currentNewsItem
                        ? currentNewsItem.content
                        : newNewsItem.content
                }
                onChange={(e) => {
                    const content = e.target.value;
                    if (currentNewsItem) {
                        setCurrentNewsItem({ ...currentNewsItem, content });
                    } else {
                        setNewNewsItem({ ...newNewsItem, content });
                    }
                }}
            />
            <button className="add-link-button" onClick={handleAddLink}>
                <LinkIcon /> Add Link
            </button>
        </div>
        <div className="news-item-image-upload">
            {(currentNewsItem?.images || newNewsItem.images || []).map(
                (image, index) => (
                    <div
                        key={index}
                        className="news-item-image-preview"
                        style={{ backgroundImage: `url(${image.url})` }}
                    >
                        <button
                            className="delete-image-button"
                            onClick={(e) => {
                                e.preventDefault();
                                const newImages = currentNewsItem
                                    ? [...currentNewsItem.images]
                                    : [...newNewsItem.images];
                                newImages.splice(index, 1);
                                if (currentNewsItem) {
                                    setCurrentNewsItem({
                                        ...currentNewsItem,
                                        images: newImages,
                                    });
                                } else {
                                    setNewNewsItem({
                                        ...newNewsItem,
                                        images: newImages,
                                    });
                                }
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </button>
                    </div>
                )
            )}
            {(currentNewsItem?.images || newNewsItem.images || []).length <
                9 && (
                <label className="news-item-image-preview image-upload-placeholder">
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={(e) => {
                            if (currentNewsItem) {
                                handleImageUpload(
                                    e,
                                    currentNewsItem,
                                    setCurrentNewsItem
                                );
                            } else {
                                handleImageUpload(
                                    e,
                                    newNewsItem,
                                    setNewNewsItem
                                );
                            }
                        }}
                        style={{ display: "none" }}
                    />
                    <AddPhotoAlternateIcon />
                    <span>Add Image</span>
                </label>
            )}
        </div>
    </div>
);

export default NewsManager;
