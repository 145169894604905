import { Fragment, useEffect, useState } from "react";

const JATSParser = ({ children }) => {
    const [parsedContent, setParsedContent] = useState("");

    const parseJATS = (data) => {
        try {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(data, "text/xml");

            const processNode = (node) => {
                // Handle text nodes
                if (node.nodeType === Node.TEXT_NODE) {
                    return node.nodeValue;
                }

                // Handle element nodes
                if (node.nodeType === Node.ELEMENT_NODE) {
                    const tagName = node.tagName.toLowerCase();

                    if (tagName === "jats:italic") {
                        return (
                            <em>
                                {" "}
                                {Array.from(node.childNodes).map(
                                    (childNode, index) => (
                                        <Fragment key={index}>
                                            {processNode(childNode)}
                                        </Fragment>
                                    )
                                )}{" "}
                            </em>
                        );
                    } else if (tagName === "jats:p") {
                        return (
                            <>
                                {Array.from(node.childNodes).map(
                                    (childNode, index) => (
                                        <Fragment key={index}>
                                            {processNode(childNode)}
                                        </Fragment>
                                    )
                                )}
                            </>
                        );
                    }
                }

                return null;
            };

            // Find the root element to start processing
            const contentNode =
                xmlDoc.querySelector("jats\\:p") || xmlDoc.querySelector("p");
            return contentNode && processNode(contentNode);
        } catch (error) {
            console.error("Error parsing JATS data:", error);
            return "Invalid JATS data";
        }
    };

    useEffect(() => {
        const content = parseJATS(children);
        setParsedContent(content);
    }, [children]);

    return parsedContent;
};

export default JATSParser;
