import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import {
    AccountSettings,
    BrowseLabs,
    BrowseListings,
    CreateListing,
    CreateLabPI,
    EditListing,
    EmailVerification,
    ErrorPage,
    Home,
    LabProfile,
    LabSignup,
    Listing,
    ListingRental,
    Manager,
    Newsfeed,
    Profile,
} from "../../pages";
import Admin from "../../pages/Admin/Admin";
import {
    ImportOrcidPublications,
    MyProfile,
    MyPublications,
    MySettings,
} from "../../pages/Account";
import {
    Finances,
    IncomingOrders,
    Inventory,
    LabSettings,
    ListingsManager,
    MembersManager,
    Messages,
    MyLabOrders,
    MyOrders,
    NewsManager,
    OverviewManager,
    PublicationsManager,
    Statistics,
} from "../../pages/LabManagerTabs";
import {
    LabListings,
    MembersTab,
    News,
    Publications,
    ResearchOverview,
} from "../../pages/LabProfileTabs";
import AdminRoute from "./AdminRoute";
import MemberRoute from "./MemberRoute";
import ProtectedRoute from "./ProtectedRoute";
import ManagerRoute from "./ManagerRoute";
import LabSignupRoute from "./LabSignupRoute";

const NavRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile/:userId" element={<Profile />} />
            <Route
                path="/account-settings"
                element={
                    <ProtectedRoute>
                        <AccountSettings />
                    </ProtectedRoute>
                }
            >
                <Route index element={<Navigate to="profile" />} />
                <Route path="profile" element={<MyProfile />} />
                <Route path="publications" element={<MyPublications />}>
                    <Route
                        path="import-orcid-publications"
                        element={
                            <ProtectedRoute>
                                <ImportOrcidPublications />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path="settings" element={<MySettings />} />
            </Route>
            <Route path="/lab/:labId" element={<LabProfile />}>
                <Route index element={<Navigate to="overview" />} />
                <Route path="overview" element={<ResearchOverview />} />
                <Route path="members" element={<MembersTab />} />
                <Route path="publications" element={<Publications />} />
                <Route path="news" element={<News />} />
                <Route path="listings" element={<LabListings />} />
            </Route>
            <Route path="/listings/" element={<BrowseListings />} />
            <Route
                path="/labsignup/"
                element={
                    <ProtectedRoute>
                        <LabSignup />
                    </ProtectedRoute>
                }
            />
            <Route path="/labs/" element={<BrowseLabs />} />
            <Route path="/listing/:listingId" element={<Listing />} />
            <Route
                path="/listingrental/:listingId"
                element={<ListingRental />}
            />
            <Route
                path="/create-lab-pi/"
                element={
                    <LabSignupRoute>
                        <CreateLabPI />
                    </LabSignupRoute>
                }
            />
            <Route
                path="/manager/:labId/listings/create-listing/"
                element={
                    <ProtectedRoute>
                        <CreateListing />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/manager/:labId/listings/edit-listing/:listingId"
                element={
                    <ProtectedRoute>
                        <EditListing />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/manager/:labId/"
                element={
                    <MemberRoute>
                        <Manager />
                    </MemberRoute>
                }
            >
                <Route index element={<Navigate to="overview" />} />
                <Route
                    path="overview"
                    element={
                        <ManagerRoute>
                            <OverviewManager />
                        </ManagerRoute>
                    }
                />
                <Route
                    path="members"
                    element={
                        <ManagerRoute>
                            <MembersManager />
                        </ManagerRoute>
                    }
                />
                <Route path="listings" element={<ListingsManager />} />
                <Route path="messages" element={<Messages />} />
                <Route path="incoming-orders" element={<IncomingOrders />} />
                <Route path="mylab-orders" element={<MyLabOrders />} />
                <Route path="my-orders" element={<MyOrders />} />
                <Route
                    path="statistics"
                    element={
                        <ManagerRoute>
                            <Statistics />
                        </ManagerRoute>
                    }
                />
                <Route
                    path="finances"
                    element={
                        <ManagerRoute>
                            <Finances />
                        </ManagerRoute>
                    }
                />
                <Route path="inventory" element={<Inventory />} />
                <Route
                    path="settings"
                    element={
                        <ManagerRoute>
                            <LabSettings />
                        </ManagerRoute>
                    }
                />
                <Route
                    path="publications"
                    element={
                        <ManagerRoute>
                            <PublicationsManager />
                        </ManagerRoute>
                    }
                />
                <Route
                    path="news"
                    element={
                        <ManagerRoute>
                            <NewsManager />
                        </ManagerRoute>
                    }
                />
            </Route>
            <Route
                path="/myorders"
                element={
                    <ProtectedRoute>
                        <MyOrders />
                    </ProtectedRoute>
                }
            />
            {/* <Route path="/shoppingcart" element={<ShoppingCart />} /> */}
            <Route path="/email-verification" element={<EmailVerification />} />
            <Route
                path="/admin"
                element={
                    <AdminRoute>
                        <Admin />
                    </AdminRoute>
                }
            />
            <Route path="/newsfeed" element={<Newsfeed />} />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    );
};

export default NavRoutes;
